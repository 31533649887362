
import StaticSave from "../../assets/images/static_save.svg";
import StaticExport from "../../assets/images/static_export.svg";
import StaticPrint from "../../assets/images/static_print.svg";
import StaticClose from "../../assets/images/static_close.svg";
import StaticClear from "../../assets/images/static_clear.svg";
import './PrintPage.css';
import ReactToPrint from "react-to-print";

const StaticOptionsPage = (props: any) => {
    const { clear = false, close = false, print = false, exports = false, save = false } = props;
    return (
        <div className="static-cont">
            {clear && <button className="stbtn">
                <img src={StaticClear} alt="" id="staticClear" />
                <span>Clear</span>
            </button>}
            {close && <button className="stbtn">
                <img src={StaticClose} alt="" id="staticClose" />
                <span>Close</span>
            </button>}
            {/* {print && <button className="stbtn tooltip123" onClick={props.onPrint}>
                <img src={StaticPrint} alt="" id="staticPrint" />
                <span>Print</span>
            </button>} */}
            {print && <ReactToPrint
                trigger={() => {
                    return (
                        <button className="stbtn tooltip123">
                            <img src={StaticPrint} alt="" id="staticPrint" />
                            <span>Print</span>
                        </button>
                    );
                }}
                content={() => props.componentRef.current} />
            }
            {exports && <button className="stbtn tooltip123" onClick={props.onExport}>
                <img src={StaticExport} alt="" id="staticExport" />
                <span>Export</span>
            </button>}
            {save && <button className="stbtn tooltip123">
                <img src={StaticSave} alt="" id="staticSave" />
                <span>Save</span>
            </button>}
        </div>
    )
}

export { StaticOptionsPage }