
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import RaleyLogo from '../../assets/images/raleyslogo_red.svg';

const LogoutPage = () => {
    const { oktaAuth } = useOktaAuth();
    useEffect(() => {
        window.onbeforeunload = () => undefined;
        localStorage.removeItem('userInfo');
        oktaAuth.signOut({ postLogoutRedirectUri: "https://cao.raleys-dev.com/login" });
    }, [oktaAuth])

    return (
        <div className="container" id="logout">
            <div className="logout-cont my-5">
                <div className="text-center" > <img className="mx-auto" src={RaleyLogo} alt="" width="100" /></div>
                <h5 className="text-secondary text-center pt-3">Logging out....</h5>
            </div>
        </div>
    )
}

export { LogoutPage };