import { Pagination } from "../../components";
import { ScheduleSearchHeading } from "../../constants";
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { between } from "./SearchUtils";

const ScheduleSearchResultPage = () => {
    const { searchResult } = useSelector((state: any) => state.searchMatrix);
    const headings = ScheduleSearchHeading;
    const [result, setState] = useState<Array<any>>([]);
    const [initialPage, setInitialPage] = useState(0);

    useEffect(() => {
        if(searchResult){
            setState(searchResult.slice(0, 10));
        }
        setInitialPage(0);
    }, [searchResult]);

    const handlePageChange = (e: any) => {
        let { selected } = e;
        setInitialPage(selected);
        if (selected === 0) {
            setState(searchResult.slice(0, 10));
        } else {
            let start = selected * 10;
            let end = start + 10;
            let list: any = between(searchResult, start, end, false);
            setState(list);
        }
    }

    return (
        <div className="round-cont mb-3">
            <div className="maintenance-cont p-3">

                <div className="">
                    <table className="table maint-table">
                        <thead className="table-dark">
                            <tr>
                                {headings.map((col: any, index: number) => { return <th scope="col" key={index}>{col}</th> })}
                            </tr>
                        </thead>
                        <tbody>
                            {result.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{row.district ? row.district : "-"}</td>
                                        <td>{row.storeId}</td>
                                        <td>{row.majorDepartment ? row.majorDepartment : "-"}</td>
                                        <td>{row.vendor}</td>
                                        <td>{row.orderDay}</td>
                                        <td>{row.orderCutoffTime}</td>
                                        <td>{row.deliveryDay}</td>
                                        <td>{row.deliveryWindowBegin}</td>
                                        <td>{row.deliveryWindowEnd}</td>
                                        <td>{row.effectiveDate ? row.effectiveDate : "-"}</td>
                                        <td>{row.endDate ? row.endDate : "-"}</td>
                                    </tr>
                                )
                            })
                            }
                            {result.length === 0 && <tr><td colSpan={12}><h6 className="text-center m-3">No Data Available</h6></td></tr>}
                        </tbody>
                    </table>
                </div>

                {result.length > 0 && <div className="pagination-cont mt-4">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                            <Pagination pageCount={(searchResult.length / 10)} handlePageClick={handlePageChange} forcePage={initialPage} initialPage={initialPage} />
                        </ul>
                    </nav>
                </div>}
            </div>
        </div>
    )


}
export { ScheduleSearchResultPage };