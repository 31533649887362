const TimeArray = ()=>{
    const interval = 15;
    const times = []; 
    let tt = 0; 
    for (let i=0;tt<24*60; i++) {
      let hh = Math.floor(tt/60);
      let mm = (tt%60); 
      times[i] ={value:("0" + (hh % 24)).slice(-2) + ':' + ("0" + mm).slice(-2)}; 
      tt = tt + interval;
    }
    return times;
}

const getYearList = ()=>{
 let year =  new Date().getFullYear();
 let arr =[]; 
 for(let i=0;i<3;i++){
   arr.push({value:year-i});
 }
 return arr;
}

export {TimeArray,getYearList};