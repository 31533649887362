
import { useState, useEffect } from 'react';
import MessageSvgSelect from '../assets/images/nav_messages_select.svg';
import ScheduleSvgSelect from '../assets/images/nav_schedule_select.svg';
import MatrixSvgSelect from '../assets/images/nav_store_select.svg';
import ReportSvgSelect from '../assets/images/nav_reports_select.svg';

import MessageSvgUnselct from '../assets/images/nav_messages_unselect.svg';
import ScheduleSvgUnselct from '../assets/images/nav_schedule_unselect.svg';
import MatrixSvgUnselct from '../assets/images/nav_store_unselect.svg';
import ReportSvgUnselct from '../assets/images/nav_reports_unselect.svg';

import MaintenanceSvgUnSelect from '../assets/images/nav_maintanace_unselect.svg';
import MaintenanceSvgSelect from '../assets/images/nav_maintanace_select.svg';
import CloneSvgUnSelect from '../assets/images/nav_clone_unselect.svg';
import CloneSvgSelect from '../assets/images/nav_clone_select.svg';
import { useOktaAuth } from '@okta/okta-react';
import { getRoleAccess, history } from '../hoc';

const SidebarLayout = () => {
    const pathname = window.location.pathname.split("/");
    const { authState } = useOktaAuth();
    const [activeMenu, setActiveMenu] = useState("schedule");
    useEffect(() => {
        setActiveMenu(pathname[pathname.length - 1]);
    }, [pathname]);

    const onTileClick = (event: any) => {
        if (event.target.title) {
            setActiveMenu(event.target.title);
            history.push(event.target.title);
        }
    }

    return (
        <div>
            {authState && authState.isAuthenticated &&
                <nav className="side-nav">
                    {getRoleAccess('schedule') && <div className={activeMenu === "schedule" ? "nav-tile d-flex selected" : "nav-tile d-flex"} title="schedule" id="scheduleDiv" onClick={onTileClick}>
                        <img src={activeMenu === "schedule" ? ScheduleSvgSelect : ScheduleSvgUnselct} alt="schedule" id="scheduleImg" title="schedule" />
                        <span className="navlink" id="schedule" title="schedule">Schedule</span>
                    </div>}

                    {getRoleAccess('matrix') && <div className={activeMenu === "matrix" ? "nav-tile d-flex selected" : "nav-tile d-flex"} title="matrix" id="matrixDiv" onClick={onTileClick}>
                        <img src={activeMenu === "matrix" ? MatrixSvgSelect : MatrixSvgUnselct} alt="Matrix" id="matrixImg" title="matrix" />
                        <span className="navlink" id="matrix" title="Matrix">Matrix</span>
                    </div>}

                    {false && getRoleAccess('alert') && <div className={activeMenu === "alerts" ? "nav-tile d-flex selected" : "nav-tile d-flex"} title="alerts" id="alertDiv" onClick={onTileClick}>
                        <img src={activeMenu === "alerts" ? MessageSvgSelect : MessageSvgUnselct} alt="alert" id="alertImg" title="alerts" />
                        <span className="navlink" id="alert" title="alerts">Alerts</span>
                    </div>}

                    {false && getRoleAccess('report') && <div className={activeMenu === "report" ? "nav-tile d-flex selected" : "nav-tile d-flex"} title="report" id="reportDiv" onClick={onTileClick}>
                        <img src={activeMenu === "report" ? ReportSvgSelect : ReportSvgUnselct} alt="report" id="reportImg" title="report" />
                        <span className="navlink" id="report" title="reports">Reports</span>
                    </div>}

                    {getRoleAccess('maintenance') && <div className={activeMenu === "maintenance" ? "nav-tile d-flex selected" : "nav-tile d-flex"} title="maintenance" id="maintenanceDiv" onClick={onTileClick} >
                        <img src={activeMenu === "maintenance" ? MaintenanceSvgSelect : MaintenanceSvgUnSelect} alt="Maintenance" id="maintenanceImg" title="maintenance" />
                        <span className="navlink" id="maintenance" title="Maintenance">Mainten-ance</span>
                    </div>}
                    {false && getRoleAccess('clone') && <div className={activeMenu === "clone" ? "nav-tile d-flex selected" : "nav-tile d-flex"} title="clone" id="cloneDiv" onClick={onTileClick} >
                        <img src={activeMenu === "clone" ? CloneSvgSelect : CloneSvgUnSelect} alt="Clone" id="cloneImg" title="clone" />
                        <span className="navlink" id="clone" title="Clone">Clone</span>
                    </div>}
                    {getRoleAccess('utility') && <div className={activeMenu === "utility" ? "nav-tile d-flex selected" : "nav-tile d-flex"} title="utility" id="cloneDiv" onClick={onTileClick} >
                        <img src={activeMenu === "utility" ? CloneSvgSelect : CloneSvgUnSelect} alt="Utility" id="cloneImg" title="utility" />
                        <span className="navlink" id="utility" title="Utility">Utility</span>
                    </div>}
                </nav>
            }
        </div>


    )
}

export { SidebarLayout };