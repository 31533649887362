import { useEffect,useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { scheduleActions } from "../../actions";
import { CurrentScheduleTable } from './utils/ScheduleUtils';

const CurrentSchedulePage = (props: any) => {
    const { result } = useSelector((state: any) => state.schedule);
    const dispatch = useDispatch();
    const storeId=props.store.storeId;
    const vendorId=props.vendor.vendorId;
    const [effectiveDate,setEffectiveDate]=useState("");
    const [weekSchedule,setWeekSchedule]=useState([]);
   
    useEffect(() => {
        if(vendorId && storeId ){
            dispatch(scheduleActions.getCurrentSchedule(storeId,vendorId));       
        }else{
            dispatch(scheduleActions.resetSchduleResult());
        }
    }, [dispatch,storeId,vendorId]);

    useEffect(() => {
        CurrentScheduleTable({ result:weekSchedule, parentId: "scheduleTable", childId: "table-schedule"});
    });

    useEffect(()=>{
       if(result && result.length && vendorId && storeId){
            setEffectiveDate(result[0]['effectiveDate']);
            setWeekSchedule(result);  
       }else{
          setEffectiveDate(""); 
          setWeekSchedule([]); 
       }
    },[result,storeId,vendorId]);

    // const deleteSchedule= ()=>{
    //     alert("Coming Soon...!!");
    // }

    return (
        <div>
            <div className="title-sec">
                <h3 className="m-0">Master Schedule</h3>

                <div className="title-right align-items-center">
                    <p className="text-title mb-0 me-3">Effective Date: <span className="text-primary">{effectiveDate!=="" ? effectiveDate:"--/--/----"}</span></p>
                    {/* <button type="button"  className="btn btn-outline-primary" onClick={deleteSchedule}>Delete</button> */}
                </div>
            </div>
            <div className="weekly-table-cont" id="scheduleTable">

            </div>
        </div>
    )
}

export { CurrentSchedulePage };