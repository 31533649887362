import { generateKey } from "../../hoc";
import { useState, useEffect } from 'react';

const ScheduleDayModal = (props: any) => {
    const [selectedDay, setDay] = useState(props.value);
    const onChange = (e: any) => {
        setDay(e.target.value);
    }
    let items = props.items;
    useEffect(() => {
        setDay("");
    }, [])
    return (
        <div>
            <div className="modal" id="scheduleDay" key={generateKey()} tabIndex={-1} aria-labelledby="scheduleDay" aria-hidden="true" style={{ display: 'block' }} >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="scheduleDay">{props.title}</h5>
                        </div>
                        <div className="modal-body">
                            <label className="form-label">Schedule day</label>
                            <select
                                className="form-select"
                                value={selectedDay}
                                onChange={onChange}>
                                <option className="d-none" value="" key={generateKey()} defaultValue="" disabled>Select Day</option>
                                {items.map((value: string) => {
                                    return <option key={generateKey()}>{value}</option>;
                                })}

                            </select>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" onClick={() => props.onAddRemoveDay("")}>Cancel</button>
                            <button type="button" className="btn btn-primary" disabled={!selectedDay} onClick={() => props.onAddRemoveDay(selectedDay)}>{props.type}</button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="modal-backdrop fade show"></div>
        </div>
    )
}
export { ScheduleDayModal };