import { useRef } from "react";
import ReactSelect, { components } from "react-select";

const MultiSelect = (props: any) => {
  const { id,selectAllLabel } = props;
  const valueRef = useRef(props.value);
  valueRef.current = props.value;
  let value = valueRef.current.map((ele: any) => ele[id]);


  const selectAllOption: any = {
    [id]: selectAllLabel
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length || value[0] === selectAllLabel
  const isOptionSelected = (option: any) =>
    valueRef.current.some(({ value }: any) => value === option[id]) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue: any, actionMeta: any) => {
    const { action, option, removedValue } = actionMeta;
    if (action === "select-option" && option[id] === selectAllOption[id]) {
      props.onChange(props.options, actionMeta);
    } else if ((action === "deselect-option" &&
      option[id] === selectAllOption[id]) ||
      (action === "remove-value" &&
        removedValue === selectAllOption[id])
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter((row: any) => row[id] !== option[id]),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
  };



  const selectedVals = getValue().map((x: any) => x[id]);
  const hiddenOptions =
    selectedVals.length > 2 ? selectedVals.slice(0, 2) : [];
  const options = getOptions().filter(
    (x: any) => !hiddenOptions.includes(x.value)
  );

  return (
    <div className="mb-3">
      <ReactSelect
        isOptionSelected={isOptionSelected}
        getOptionValue={(option: any) => option[props.label] || selectAllOption[id]}
        getOptionLabel={(option: any) => option[props.label] || selectAllOption[id]}
        options={options}
        value={getValue()}
        onChange={onChange}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        maxMenuHeight={175}
        components={{ MultiValue }}
        isMulti
      />
    </div>
  );
};

export { MultiSelect };

const MoreSelectedBadge = ({ items }: any) => {
  const style = {
    background: "#F0E5DF",
    fontSize: "13px",
    height:'26px',
    width:'95px',
    padding:'5px',
    marginLeft:"8px",
    marginRight:'-18px'
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} selected`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }: any) => {
  const maxToShow = 2;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x: any) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
