const CLIENT_ID = "0oa3rw26vycwWyequ4x7";
const ISSUER = "https://raleys.okta.com/oauth2/Okta";
const DISABLEHTTPSCHECK = false;
const REDIRECT_URI = "https://cao.raleys-dev.com/login/callback";
const SCOPES = "openid, profile, email";
const PKCE = true;
const OktaConfig= {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri:REDIRECT_URI,
    scopes:SCOPES.split(","),
    pkce: Boolean(PKCE),
    disableHttpsCheck: Boolean(DISABLEHTTPSCHECK),
  } 
};
// console.log(OktaConfig);

export {OktaConfig};
