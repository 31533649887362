import { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { fscheduleActions } from "../../../actions";
import { ConfirmModal, DropDown, Select } from "../../../components";
import { DeliveryDays, messageConstants, OrderDays, symbolsArr } from "../../../constants";
import { generateKey, TimeArray } from '../../../hoc';
import { errorState, validateSchedule } from "./ScheduleValidator";
import { ScheduleWarnings, warningState } from "./ScheduleWarnings";

const ScheduleDay = forwardRef((props: any, ref: any) => {
    let items: Array<any> = OrderDays.filter(f => props.items.includes(f.value));
    let state = props.data;
    const timeArr= TimeArray();
    const index = props.index;

    if (props.holidayData) {
        state.effectiveDate = props.holidayData.effectiveDate;
        state.endDate = props.holidayData.endDate;
        state.holidayId = props.holidayData.holidayId;
        state.status= props.holidayData.status;
    }

    const dispatch = useDispatch();
    const [errors, setErrors] = useState(errorState);
    const [warnings, setWarnings] = useState(warningState);

     useEffect(()=>{
         setErrors(errorState);
         setWarnings(warningState);
     },[props.storeId,props.vendorId]);

    const handleChange = (e: any,name?:string) => {
        props.onChange(e, index,name);
    }

    const validate = () => {
        const { isValid, error} = validateSchedule(state);
        setErrors(error);
        return isValid;
    }
    const checkWarnings=()=>{
        const {warnings} = ScheduleWarnings(state);
        setWarnings(warnings);
        return warnings;
    }
    useImperativeHandle(ref, () => ({
        validateChild() {
            return { isValid: validate(),warnings:checkWarnings() };
        }
    }));

    const clearState = () => {
        props.onClear(index);
    }

    const saveFutureSchedule = () => {
        checkWarnings();
        if (validate()) {
            ConfirmModal({
                message: messageConstants.SAVE_SCHEDULE_CONFIRM, confirm: "Yes", cancel: "No", onValueChange: function onValueChange(confirm: any) {
                    if (confirm) {
                        const { storeId, vendorId, data, currentState } = props;
                        dispatch(fscheduleActions.saveFutureSchedule(currentState, data, storeId, vendorId, data.holidayId));
                    }
                }
            });
        }
    }
    
    return (
        <div>
            <form>
                <div className="row">
                    <div className="col-md-4">
                        <div className="label">
                            <label>Order Day</label>
                        </div>
                        <Select name="orderDay" label="key" labelValue="value" id="orderDay" items={items} value={state.orderDay} onChange={handleChange} />
                    </div>

                    <div className="col-md-4">
                        <div className="label">
                            <label>Order Cutoff Time</label>
                        </div>
                        <DropDown key={generateKey()} id="orderCutoffTime" className="form-control"  name="orderCutoffTime" options={timeArr} label="value" value={{value:state.orderCutoffTime}} onChange={(e:any)=>handleChange(e.value,'orderCutoffTime')}></DropDown>
                        <span className="error-class">{errors.orderCutoffTime}</span>
                    </div>
                    <div className="col-md-4">
                        <div className="label">
                            <label>Delivery Day</label>
                        </div>
                        <Select name="deliveryDay" label="key" labelValue="value" items={DeliveryDays} value={state.deliveryDay} onChange={handleChange}/>
                        {warnings.deliveryDay && <span className="warn-class">&#9888; {warnings.deliveryDay}</span>}
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-4">
                        <div className="label">
                            <label>Delivery Hrs</label>
                        </div>
                        <input
                            className="form-control"
                            value={state.deliveryHour}
                            type="number"
                            min={0}
                            max={168}
                            name="deliveryHour"
                            onChange={handleChange}
                            onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                        />
                        <span className="error-class">{errors.deliveryHour}</span>
                    </div>
                    <div className="col-md-4">
                        <div className="label">
                            <label>Delivery Begin</label>
                        </div>
                        <DropDown key={generateKey()} id="deliveryWindowBegin" className="form-control"  name="deliveryWindowBegin" options={timeArr} label="value" value={{value:state.deliveryWindowBegin}} onChange={(e:any)=>handleChange(e.value,'deliveryWindowBegin')}></DropDown>
                        <span className="error-class">{errors.deliveryWindowBegin}</span>

                    </div>

                    <div className="col-md-4">
                        <div className="label">
                            <label>Delivery End</label>
                        </div>
                        <DropDown key={generateKey()} id="deliveryWindowEnd" className="form-control"  name="deliveryWindowEnd" options={timeArr} label="value" value={{value:state.deliveryWindowEnd}} onChange={(e:any)=>handleChange(e.value,'deliveryWindowEnd')}></DropDown>
                        <span className="error-class">{errors.deliveryWindowEnd}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="label">
                            <label>On Shelf Day</label>
                        </div>
                        <Select name="onShelfDay" label="key" labelValue="value" items={DeliveryDays} value={state.onShelfDay} onChange={handleChange} />
                        {warnings.onShelfDay && <span className="warn-class">&#9888; {warnings.onShelfDay}</span>}
                    </div>
                    <div className="col-md-4">
                        <div className="label">
                            <label>On Shelf Hrs</label>
                        </div>
                        <input
                            className="form-control"
                            value={state.onShelfHours}
                            type="number"
                            min={0}
                            max={168}
                            name="onShelfHours"
                            onChange={handleChange}
                            onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                        />
                        <span className="error-class">{errors.onShelfHours}</span>

                    </div>
                    <div className="col-md-4">
                        <div className="label">
                            <label>Create Lead Time</label>
                        </div>
                        <input
                            className="form-control"
                            value={state.createLeadTime}
                            type="number"
                            min={0}
                            max={168}
                            name="createLeadTime"
                            onChange={handleChange}
                        />

                        <span className="error-class">{errors.createLeadTime}</span>

                    </div>
                </div>
            </form>
            <div className={!props.holidayData ? "text-center my-4":"position-relative text-center my-4"}>
                <button type="button" id="clr-btn" className={!props.holidayData ? "btn btn-outline-secondary my-3":"btn btn-link btn-link-sec my-3"} onClick={clearState}>Clear</button>
                {props.holidayData && <button type="button" id="save-btn" className="btn btn-primary ripple my-3" onClick={saveFutureSchedule}>Save</button>}
            </div>
        </div>
    )
});

export { ScheduleDay };