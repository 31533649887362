export const holidayConstants = {  
    GET_HOLIDAY_REQUEST: 'GET_HOLIDAY_REQUEST',
    GET_HOLIDAY_SUCCESS: 'GET_HOLIDAY_SUCCESS',
    GET_HOLIDAY_FAILURE: 'GET_HOLIDAY_FAILURE',

    SAVE_HOLIDAY_REQUEST: 'SAVE_HOLIDAY_REQUEST',
    SAVE_HOLIDAY_SUCCESS: 'SAVE_HOLIDAY_SUCCESS',
    SAVE_HOLIDAY_FAILURE: 'SAVE_HOLIDAY_FAILURE',

    PUBLISH_HOLIDAY_REQUEST: 'PUBLISH_HOLIDAY_REQUEST',
    PUBLISH_HOLIDAY_SUCCESS: 'PUBLISH_HOLIDAY_SUCCESS',
    PUBLISH_HOLIDAY_FAILURE: 'PUBLISH_HOLIDAY_FAILURE',

    ACTIVATE_HOLIDAY_REQUEST: 'ACTIVATE_HOLIDAY_REQUEST',
    ACTIVATE_HOLIDAY_SUCCESS: 'ACTIVATE_HOLIDAY_SUCCESS',
    ACTIVATE_HOLIDAY_FAILURE: 'ACTIVATE_HOLIDAY_FAILURE',

    DELETE_HOLIDAY_REQUEST: 'DELETE_HOLIDAY_REQUEST',
    DELETE_HOLIDAY_SUCCESS: 'DELETE_HOLIDAY_SUCCESS',
    DELETE_HOLIDAY_FAILURE: 'DELETE_HOLIDAY_FAILURE',

    CLONE_HOLIDAY_REQUEST: 'CLONE_HOLIDAY_REQUEST',
    CLONE_HOLIDAY_SUCCESS: 'CLONE_HOLIDAY_SUCCESS',
    CLONE_HOLIDAY_FAILURE: 'CLONE_HOLIDAY_FAILURE',
};
