export const scheduleConstants = {  
    GET_WSCHEDULE_REQUEST: 'GET_WSCHEDULE_REQUEST',
    GET_WSCHEDULE_SUCCESS: 'GET_WSCHEDULE_SUCCESS',
    GET_WSCHEDULE_FAILURE: 'GET_WSCHEDULE_FAILURE',

    SAVE_WSCHEDULE_REQUEST: 'SAVE_WSCHEDULE_REQUEST',
    SAVE_WSCHEDULE_SUCCESS: 'SAVE_WSCHEDULE_SUCCESS',
    SAVE_WSCHEDULE_FAILURE: 'SAVE_WSCHEDULE_FAILURE',

    GET_SHISTORY_REQUEST: 'GET_SHISTORY_REQUEST',
    GET_SHISTORY_SUCCESS: 'GET_SHISTORY_SUCCESS',
    GET_SHISTORY_FAILURE: 'GET_SHISTORY_FAILURE',

    GET_WSCHEDULELIST_REQUEST: 'GET_WSCHEDULELIST_REQUEST',
    GET_WSCHEDULELIST_SUCCESS: 'GET_WSCHEDULELIST_SUCCESS',
    GET_WSCHEDULELIST_FAILURE: 'GET_WSCHEDULELIST_FAILURE',
};
