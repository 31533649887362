
const validateSchedule=(state:any)=>{
    let error: any = {};
    let isValid = true;
   
    if (typeof state["createLeadTime"] !== "undefined") {
        if (Number(state['createLeadTime']) <= 0) {
            isValid = false;
            error["createLeadTime"] = 'Lead time must be greater than 0.';
        }
        if (Number(state['createLeadTime']) > 168) {
            isValid = false;
            error["createLeadTime"] = 'Lead time cannot be greater than 168.';
        }
    }
    if (typeof state["orderCutoffTime"] === "undefined" || state["orderCutoffTime"] === "") {
        isValid = false;
        error["orderCutoffTime"] = 'CutOff time cannot be null/blank.';
    }
    if (typeof state["deliveryWindowEnd"] === "undefined" || state["deliveryWindowEnd"] === "") {
        isValid = false;
        error["deliveryWindowEnd"] = 'Delivery end time cannot be null/blank.';
    }
    if (typeof state["deliveryWindowBegin"] === "undefined" || state["deliveryWindowBegin"] === "") {
        isValid = false;
        error["deliveryWindowBegin"] = 'Delivery begin time cannot be null/blank.';
    }
    if (typeof state["deliveryHour"] !== "undefined") {
        if (Number(state['deliveryHour']) <= 0) {
            isValid = false;
            error["deliveryHour"] = 'Delivery hours must be greater than 0.';
        }
        if (Number(state['deliveryHour']) > 168) {
            isValid = false;
            error["deliveryHour"] = 'Delivery hours cannot be greater than 168.';
        }
    }
    if (typeof state["onShelfHours"] !== "undefined") {
        if (Number(state['onShelfHours']) <= 0) {
            isValid = false;
            error["onShelfHours"] = 'On Shelf hours must be greater than 0.';
        }
        if (Number(state['onShelfHours']) > 168) {
            isValid = false;
            error["onShelfHours"] = 'On Shelf hours cannot be greater than 168.';
        }
    }
   
    return {isValid,error};
}

const errorState={
    createLeadTime: '',
    onShelfHours: '',
    deliveryHour: '',
    orderCutoffTime: '',
    deliveryWindowEnd: '',
    deliveryWindowBegin: '',
    deliveryDay:''
}

export {validateSchedule,errorState};