export const fScheduleConstants = {  
    GET_FSCHEDULE_REQUEST: 'GET_FSCHEDULE_REQUEST',
    GET_FSCHEDULE_SUCCESS: 'GET_FSCHEDULE_SUCCESS',
    GET_FSCHEDULE_FAILURE: 'GET_FSCHEDULE_FAILURE',

    SAVE_FSCHEDULE_REQUEST: 'SAVE_FSCHEDULE_REQUEST',
    SAVE_FSCHEDULE_SUCCESS: 'SAVE_FSCHEDULE_SUCCESS',
    SAVE_FSCHEDULE_FAILURE: 'SAVE_FSCHEDULE_FAILURE',

    GET_FHOLIDAY_REQUEST: 'GET_FHOLIDAY_REQUEST',
    GET_FHOLIDAY_SUCCESS: 'GET_FHOLIDAY_SUCCESS',
    GET_FHOLIDAY_FAILURE: 'GET_FHOLIDAY_FAILURE',

};
