const CurrentScheduleHeadings = ["Order Day", "Order Cutoff", "Delivery Day/Hours", "Delivery Begin", "Delivery End", "On Shelf Day/Hours",
    "Create Lead Time", "Transporter"];

const CurrentScheduleKeys = ['orderDay', 'orderCutoffTime', 'deliveryDay', 'deliveryWindowBegin', 'deliveryWindowEnd', 'onShelfDay',
    'createLeadTime', 'transporter']

 const ScheduleSearchHeading = ['District','Store','Major Dept.','Vendor','Order Day','Cutoff Time','Delivery Day',
'Delivery Begin','Delivery End','Effective Date','End Date'];   

const MaintenanceTableHeading=["Schedule", "Effective Date", "End Date", "Status", "Action"];

const HistoryTableHeading=["Modified By", "Schedule", "Modified Date", "Modified Time", "Order Day",
"Field Name","Changed From","Changed To"];

const MatrixHeading=["Order Day","Sunday","Monday","Tuesday", "Wednesday", "Thursday","Friday","Saturday"];
const MtxSubHeading=["Order cutoff", "Delivery", "Order cutoff", "Delivery", "Order cutoff", "Delivery", "Order cutoff", "Delivery", "Order cutoff", "Delivery", "Order cutoff", "Delivery", "Order cutoff", "Delivery"];

export { CurrentScheduleHeadings, CurrentScheduleKeys,ScheduleSearchHeading,MaintenanceTableHeading,HistoryTableHeading,
    MatrixHeading,MtxSubHeading};
