import { getLandPageAccess, getUserRole, history } from "../../hoc";
import CaoLogo from '../../assets/images/cao_logo_small.svg';
import RaleyLogo from '../../assets/images/raleyslogo_red.svg';
import ODTImg from '../../assets/images/image_ODT.svg';
import MatrixImg from '../../assets/images/image_matrix.svg';
import ReportImg from '../../assets/images/image_reports.svg';
import ErrorIcon from '../../assets/images/ic_error.svg';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions/user.actions';


const CaoDashboardPage = () => {
    const { userInfo } = useSelector((state: any) => state.user);
    const { authState, oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let cleanUp = false;
        if (authState && authState.isAuthenticated) {
            oktaAuth.getUser().then((info: any) => {
                console.log(' ------  AUTHED  ------ ');
                console.log(info);
                console.log(info.groups);
                if (cleanUp) return;
                else {
                    localStorage.removeItem("userInfo");
                    localStorage.setItem('userInfo', JSON.stringify(info));
                    dispatch(userActions.storeUserInfo(info));
                }
            });
        }
        return () => { cleanUp = true; };
    }, [authState, oktaAuth, dispatch]);

    useEffect(() => {
        setLoading(true);
    }, [userInfo]);

    const navigateRoute = (route: string) => {
        let role = getUserRole().toLocaleLowerCase();
        if (role === 'district') {
            history.push("search");
        } else {
            history.push(route);
        }

    }

    return (
        <>
            {isLoading && <div>
                <nav className="navbar navbar-light ">
                    <div className="container">
                        <button className="navbar-brand bg-transparent border-0">
                            <img src={CaoLogo} alt="" id="caoLogo" width="50" height="50"
                                className="d-inline-block align-middle" />
                            <span>CAO Dashboard</span>
                        </button>

                        <img src={RaleyLogo} id="raleyLogo" alt="Raley" width="" height="40" className="ml-auto" />
                    </div>
                </nav>

                {getLandPageAccess('ODT') && <section className="sec-left cat1 sec-bg-color">
                    <div className="container h-100">
                        <div className="row h-100">

                            <div className="col-6 vbox">
                                <div className="hedding-info">
                                    <h1>Order Delivery Tool</h1>
                                    <p>Raley’s order delivery tool for managing vendor schedules to be loaded into Raley’s Computer Assisted Ordering (CAO) application. </p>
                                    <button className="btn btn-primary btn-lg ripple" id="odt-btn" onClick={() => navigateRoute('schedule')}>Order Delivery Tool</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sec-imgcont">
                        <img className="sec-img cat1-img" id="odtImg" src={ODTImg} alt="ODT" />
                    </div>
                </section>}

                {getLandPageAccess('OSM') && <section className="sec-right cat1">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-6">&nbsp;</div>
                            <div className="col-6 vbox">
                                <div className="hedding-info ">
                                    <h1>Order Schedule Matrix</h1>
                                    <p> Raley’s vendor order delivery schedule specific to store by department, vendor and days of the week.  </p>
                                    <button id="sos-btn" className="btn btn-primary btn-lg ripple" onClick={() => navigateRoute('matrix')}>Store Order Schedule Matrix</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sec-imgcont">
                        <img className="sec-img cat1-img" id="matrixImg" src={MatrixImg} alt="ODT" />
                    </div>
                </section>}

                {false && getLandPageAccess('REPORT') && <section className="sec-left cat1">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-6 vbox">
                                <div className="hedding-info">
                                    <h1> Store Reports  </h1>
                                    <p>Presentation of Business Objects Store Reports</p>
                                    <button className="btn btn-primary btn-lg ripple">View Reports</button>
                                </div>
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>

                    <div className="sec-imgcont">
                        <img className="sec-img cat1-img" id="reportImg" src={ReportImg} alt="ODT" />
                    </div>
                </section>}

                {(!getLandPageAccess('REPORT') && !getLandPageAccess('OSM') && !getLandPageAccess('ODT')) &&
                    <div className="wrap-container m-5">
                        <div className="text-center hr-line-success"><img className="mx-auto" src={ErrorIcon} alt="" width="80" /> </div>
                        <h5 className="text-secondary text-center pt-3">Access Denied</h5>
                        <h3 className="text-center pt-3"><u>You dont have permission to view this site.</u></h3>
                    </div>
                }

                <footer className="footer">
                    ©2021-2022 Raley's All Rights Reserved
                </footer>
            </div>}
        </>
    )

}

export { CaoDashboardPage };