import { vendorStoreConstants } from "../constants";
const initialState = {
    storeList: [],
    districtStores: [],
    districtList:[]
};

export function store(state = initialState, action: any) {
    switch (action.type) {
        case vendorStoreConstants.GET_STORE_REQUEST:
            return { ...state };
        case vendorStoreConstants.GET_STORE_SUCCESS:
            return {
                ...state,
                storeList: action.response.data.result
            };
        case vendorStoreConstants.GET_STORE_FAILURE:
            return { ...state };
        case vendorStoreConstants.GET_SBYDIST_REQUEST:
            return { ...state };
        case vendorStoreConstants.GET_SBYDIST_SUCCESS:
            return {
                ...state,
                districtStores: action.response.data.result
            };
        case vendorStoreConstants.GET_SBYDIST_FAILURE:
            return { ...state };
        case vendorStoreConstants.GET_SDIST_REQUEST:
            return { ...state };
        case vendorStoreConstants.GET_SDIST_SUCCESS:
            return {
                ...state,
                districtList: action.response.data.result
            };
        case vendorStoreConstants.GET_SDIST_FAILURE:
            return { ...state };
        default:
            return state
    }
}