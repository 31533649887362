import { AxiosResponse } from 'axios';
import { ErrorModal, SuccessModal } from '../components';
import { holidayConstants } from '../constants';
import {fetch} from '../hoc';

export const holidayActions = {
    updateHoliday,
    getHolidayList,
    publishHoliday,
    activateHoliday,
    deleteHoliday,
    cloneSchedule
};

function getHolidayList() {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'namedSchedule/list?maintenance=true',   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: holidayConstants.GET_HOLIDAY_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: holidayConstants.GET_HOLIDAY_SUCCESS, response } }
    function failure(error: any) { return { type: holidayConstants.GET_HOLIDAY_FAILURE, error } }
}

function updateHoliday(body:any) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'put',
            url: 'namedSchedule/update',  
            data: body
        }).then(function (response) {
            dispatch(success(response));
            SuccessModal(response.data.message);
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
}

function publishHoliday(body:any) {
    body.status = "Publish";
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'put',
            url: 'namedSchedule/publish',  
            data: body
        }).then(function (response) {
            dispatch(success(response));
            SuccessModal(response.data.message);
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
  
}

function activateHoliday(body:any) {
    body.status = "Active";
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'put',
            url: 'namedSchedule/active',  
            data: body
        }).then(function (response) {
            dispatch(success(response));
            SuccessModal(response.data.message);
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
  
}
//common methods
function request() { return { type: holidayConstants.SAVE_HOLIDAY_REQUEST } }
function success(response: AxiosResponse<any>) { return { type: holidayConstants.SAVE_HOLIDAY_SUCCESS, response } }
function failure(error: any) { return { type: holidayConstants.SAVE_HOLIDAY_FAILURE, error } }  


function deleteHoliday(id:number) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'delete',
            url: 'namedSchedule/delete/'+id,  
        }).then(function (response) {
            dispatch(success(response));
            SuccessModal(response.data.message);
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: holidayConstants.DELETE_HOLIDAY_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: holidayConstants.DELETE_HOLIDAY_SUCCESS, response } }
    function failure(error: any) { return { type: holidayConstants.DELETE_HOLIDAY_FAILURE, error } }
}


function cloneSchedule(name:string) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'namedSchedule/clone?name='+name,  
        }).then(function (response) {
            if(response.data.status === 200){
                dispatch(success(response));
                SuccessModal(response.data.message);
            }else{
                ErrorModal(response.data);  
            }   
 
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: holidayConstants.CLONE_HOLIDAY_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: holidayConstants.CLONE_HOLIDAY_SUCCESS, response } }
    function failure(error: any) { return { type: holidayConstants.CLONE_HOLIDAY_FAILURE, error } }
}