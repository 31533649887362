import { vendorStoreConstants } from "../constants";
const initialState = {
    vendorList: [],
    majorDeptList: [],
    sourceList: [],
    minorDeptList: []
};

export function vendor(state = initialState, action: any) {
    switch (action.type) {
        case vendorStoreConstants.GET_VENDOR_REQUEST:
            return { ...state };
        case vendorStoreConstants.GET_VENDOR_SUCCESS:
            return {
                ...state,
                vendorList: action.response.data.result
            };
        case vendorStoreConstants.GET_VENDOR_FAILURE:
            return { ...state };
        case vendorStoreConstants.GET_VDEPT_REQUEST:
            return { ...state };
        case vendorStoreConstants.GET_VDEPT_SUCCESS:
            return {
                ...state,
                majorDeptList: action.response.data.result
            };
        case vendorStoreConstants.GET_VDEPT_FAILURE:
            return { ...state };
        case vendorStoreConstants.GET_MIDEPT_REQUEST:
            return { ...state };
        case vendorStoreConstants.GET_MIDEPT_SUCCESS:
            return {
                ...state,
                minorDeptList: action.response.data.result
            };
        case vendorStoreConstants.GET_MIDEPT_FAILURE:
            return { ...state };

        case vendorStoreConstants.GET_SOURCE_REQUEST:
            return { ...state };
        case vendorStoreConstants.GET_SOURCE_SUCCESS:
            return {
                ...state,
                sourceList: action.response.data.result
            };
        case vendorStoreConstants.GET_SOURCE_FAILURE:
            return { ...state };

        default:
            return state
    }
}