import { useState } from "react";
import { useDispatch } from "react-redux";
import { holidayActions } from "../../../actions";
import { messageConstants } from "../../../constants";

const CloneSchedulePage = () => {
    const [state, setState] = useState({ scheduleName: "", error: "" });
    const dispatch = useDispatch();
    const handleChange = (e: any) => {
        const value = e.target.value.replace(/\s+/g, " ");
        setState(state => ({ ...state, scheduleName: value }));
    }
    const cloneSchedule = () => {
        if(validate()){
            dispatch(holidayActions.cloneSchedule(state.scheduleName.trim()));
        }
    }
    const validate = () => {
        let split = state.scheduleName.split("-");
        let isValid = true;
        let message = "";
        if (split.length !== 2 || state.scheduleName.length > 20) {
            message = messageConstants.VALID_HOLIDAY_NAME;
            isValid = false;
        } else if (split.length === 2) {
            let name = split[0].replace(/\s/g,'');
            let year = split[1].replace(/\s/g,'');
            let curr_year= new Date().getFullYear();
            if (!isNaN(Number(name)) || isNaN(Number(year)) || year.length !== 4 || (Number(year) < 1920)) {
                message = messageConstants.VALID_HOLIDAY_NAME;
                isValid = false;
            }
            if((Number(year) < curr_year)){
                message = messageConstants.VALID_HOLIDAY_YEAR;
                isValid = false;
            }
            if(state.scheduleName.trim().split(' ').length < 3){
                message = messageConstants.VALID_HOLIDAY_NAME;
                isValid = false;
            }
            
        }
        setState(state => ({ ...state, error: message }));
        return isValid;
    }

    return (
        <div className="col-6">
            <div className="headding-cont">
                <h2>Clone a Schedule</h2>
            </div>
            <div className="round-cont p-3 h280">
                <p className="text-title">From Schedule : <span className="text-primary">Master</span></p>
                <div className="col-6 mb-3" id="createSchedule">
                    <label className="form-label">Create Schedule Name </label>
                    <input type="name" className="form-control" id="setSchedule" onChange={handleChange} name="scheduleName" value={state.scheduleName} placeholder="" />
                </div>
                <span className="error-class">{state.error}</span>
                <div className="btn-cont mt-3 pt-3">
                    <button className="btn btn-primary" id="clone-btn" onClick={cloneSchedule} disabled={!state.scheduleName}>Clone</button>
                </div>
            </div>
        </div>
    );
}
export { CloneSchedulePage };