import { holidayConstants } from "../constants";
import { SortByDate } from "../hoc";

const initialState = {
    result: [],
};

export function holiday(state = initialState, action: any) {
    switch (action.type) {
        case holidayConstants.GET_HOLIDAY_REQUEST:
            return { ...state };
        case holidayConstants.GET_HOLIDAY_SUCCESS:
            return {
                ...state,
                result: SortByDate(action.response.data.result, 'effectiveDate')
            };
        case holidayConstants.GET_HOLIDAY_FAILURE:
            return { ...state };
        case holidayConstants.SAVE_HOLIDAY_REQUEST:
            return { ...state };
        case holidayConstants.SAVE_HOLIDAY_SUCCESS:
            return {
                ...state,
                result: updateState(action.response.data.result[0], state)
            };
        case holidayConstants.SAVE_HOLIDAY_FAILURE:
            return { ...state };
        case holidayConstants.DELETE_HOLIDAY_REQUEST:
            return { ...state };
        case holidayConstants.DELETE_HOLIDAY_SUCCESS:
            return {
                result: [...state.result.filter((row: any) => row.holidayId !== action.response.data.result)],
            };
        case holidayConstants.DELETE_HOLIDAY_FAILURE:
            return { ...state };
        case holidayConstants.CLONE_HOLIDAY_FAILURE:
            return { ...state };
        case holidayConstants.CLONE_HOLIDAY_REQUEST:
            return { ...state };
        case holidayConstants.CLONE_HOLIDAY_SUCCESS:
            return {
                ...state,
                result: addNewRecord(state.result,action.response.data.result)
            };
        default:
            return state
    }
}

const updateState = (payload: any, state: any) => {
    const index = state.result.findIndex((row: any) => row.holidayId === payload.holidayId);
    const newArray: Array<any> = [...state.result];
    newArray[index].effectiveDate = payload.effectiveDate;
    newArray[index].endDate = payload.endDate;
    newArray[index].namedSchedule = payload.namedSchedule;
    newArray[index].status = payload.status ? payload.status : newArray[index].status;
    newArray[index].isSaved = true;
    return SortByDate(newArray, 'effectiveDate');
}

const addNewRecord = (oldArray:Array<any>,newObj:any)=>{
   oldArray.push(newObj);
   return SortByDate(oldArray, 'effectiveDate');
}
