import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { vendorStoreActions } from "../../actions";
import { ConfirmModal, DropDown } from "../../components";
import { generateKey } from "../../hoc";
import { StoreInterface, VendorInterface } from './ScheduleInterfaces';

const UtilityHomePage = () => {
    const storeList: StoreInterface[] = useSelector((state: any) => state.store.storeList);
    const vendorList: VendorInterface[] = useSelector((state: any) => state.vendor.vendorList);

    const [store, setStore] = useState({ storeId: "" });
    const [vendor, setVendor] = useState({ vendor: "", vendorId: "" });

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(vendorStoreActions.getStoreList());
        dispatch(vendorStoreActions.getVendorList());
    }, [dispatch]);

    const changeStore = (e: any) => {
        setStore(e);
    }

    const changeVendor = (e: any) => {
        setVendor(e);
    }

    const relaunchService = () => {
        dispatch(vendorStoreActions.relaunchHPCSService('141'));
    }

    const openConfirmDialog = () => {
        ConfirmModal({message:'Are you sure you want to permantently void this invoice? This action cannot be undone!',confirm:"Void",cancel:"Cancel",onValueChange:function onValueChange(confirm:any){
            if(confirm){
                
            }
        }});
    }

    return (
        <div className="tab-content">
            <div className="headding-cont">
                <h2 className="d-inline-block">Store Utilities</h2>
                {/* <button type="button" className="btn-small clone-btn">Clone</button> */}
                <p>Execute store-level actions in Itasca Magic</p>
            </div>
            <div className="round-cont">
                <div className="schdule-tabs">
                    <nav className="schdule-nav">
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-1-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1"
                                aria-selected="true">Scan Gun Locks</button>
                            <button className="nav-link" id="nav-2-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2"
                                aria-selected="false">Void Invoice</button>
                            <button className="nav-link" id="nav-3-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-3" type="button" role="tab" aria-controls="nav-3"
                                aria-selected="false">Void Transfer</button>
                            <button className="nav-link" id="nav-4-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-4" type="button" role="tab" aria-controls="nav-4"
                                aria-selected="false">User Management</button>
                            <button className="nav-link" id="nav-5-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-5" type="button" role="tab" aria-controls="nav-5"
                                aria-selected="false">PO to Supplier</button>
                        </div>
                    </nav>
                </div>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                        <div className="p-3 m-2">
                            <div className="form-cont">
                                <div className="d-inline-block">
                                    <label className="form-label">Store</label>
                                    <DropDown key={generateKey()} id="store" options={storeList} label="storeId" value={store.storeId ? store:""} onChange={changeStore}></DropDown>
                                </div>
                            </div>
                            <button className="btn btn-primary" id="search-btn" onClick={relaunchService} disabled={!(store.storeId)}>Release Scan Gun Locks</button>
                            <div className="mt-3">
                                <p>Releasing the Scan Gun locks will allow access to the read-only views allowing users make changes again.<br></br>You can confirm it was unlocked by going into Itasca Magic (CAO), then selecting <b>File</b> &gt; <b>Open</b> &gt; <b>Cycle Counts</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                        <div className="col-6">
                            <div className="p-3 m-2 h280">
                                <div className="form-cont">
                                    <div className="d-inline-block">
                                        <label className="form-label">Store</label>
                                        <DropDown key={generateKey()} id="store" options={storeList} label="storeId" value={store.storeId ? store:""} onChange={changeStore}></DropDown>
                                    </div>
                                </div>
                                <div className="col-12 mb-2" id="voidInvoice">
                                    <div className="search-form-cont mx-auto form-width" >
                                        <div className="row g-3">
                                            <div className="col">
                                                <label className="form-label">Invoice ID <span>*</span></label>
                                                <input type="name" className="form-control" id="invoiceNumber" name="scheduleName" placeholder="" />
                                            </div>
                                            <div className="col text-center orcont">
                                                <div className="or-sap-centered">(OR)</div>
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Vendor <span>*</span></label>
                                                <DropDown key={generateKey()} id="vendor" options={vendorList} label="vendor" value={vendor.vendorId ? vendor:""} onChange={changeVendor}></DropDown>
                                            </div>
                                            <div className="col">
                                                <label className="form-label">Invoice Number <span>*</span></label>
                                                <input type="name" className="form-control" id="invoiceNumber" name="scheduleName" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary" id="clone-btn" onClick={openConfirmDialog}>Void Invoice</button>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
                        <div className="col-6">
                            <div className="p-3 m-2 h280">
                                <div className="form-cont">
                                    <div className="d-inline-block">
                                        <label className="form-label">Store</label>
                                        <DropDown key={generateKey()} id="store" options={storeList} label="storeId" value={store.storeId ? store:""} onChange={changeStore}></DropDown>
                                    </div>
                                </div>
                                <div className="col-12 mb-2">
                                    <div className="search-form-cont mx-auto form-width" >
                                        <div className="row g-3">
                                            <div className="col">
                                                <label className="form-label">Transfer ID <span>*</span></label>
                                                <input type="name" className="form-control" id="transferId" name="tranferId" placeholder="" />
                                            </div>
                                            <div className="col text-center orcont">
                                                {false && <div className="or-sap-centered">(OR)</div>}
                                            </div>
                                            <div className="col">
                                                {false && <label className="form-label">Transfer Number <span>*</span></label>}
                                                {false && <input type="name" className="form-control" id="transferNumber" name="transferNumber" placeholder="" />}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary" id="clone-btn" onClick={openConfirmDialog}>Void Transfer</button>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab">
                        <div className="col-6 m-2 p-3">
                            Coming soon...
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-5" role="tabpanel" aria-labelledby="nav-5-tab">
                        <div className="col-6 m-2 p-3">
                            Manually trigger jobs to send late POs to vendors. Note: Batch jobs can only be triggered when the job is not already running.
                            <div className="col p-3">
                                <button className="btn btn-primary" id="clone-btn" onClick={openConfirmDialog}>Void Transfer</button>
                            </div>
                            <div className="col p-3">
                                <button className="btn btn-primary" id="clone-btn" onClick={openConfirmDialog}>Void Transfer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export { UtilityHomePage };