
import { AxiosResponse } from 'axios';
import { ErrorModal, SuccessModal } from '../components';
import { fScheduleConstants } from '../constants';
import {fetch, SortWeekDays} from '../hoc';

export const fscheduleActions = {
    getFutureSchedule,
    saveFutureSchedule,
    getFutureHolidayList,
    removeScheduleDay
};

function getFutureHolidayList() {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'namedSchedule/list',   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: fScheduleConstants.GET_FHOLIDAY_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: fScheduleConstants.GET_FHOLIDAY_SUCCESS, response } }
    function failure(error: any) { return { type: fScheduleConstants.GET_FHOLIDAY_FAILURE, error } }
}

function getFutureSchedule(storeId:number,vendorId:number,holidayId:number) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'futureSchedule/list/'+storeId+"/"+vendorId+"/"+holidayId,   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: fScheduleConstants.GET_FSCHEDULE_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: fScheduleConstants.GET_FSCHEDULE_SUCCESS, response } }
    function failure(error: any) { return { type: fScheduleConstants.GET_FSCHEDULE_FAILURE, error } }
}

function saveFutureSchedule(currentState:Array<any>,schedules:any,storeId:number,vendorId:number,holidayId:number) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'futureSchedule/save/'+storeId+"/"+vendorId+"/"+holidayId, 
            data:{"list":[schedules]}
            //data:schedules
        }).then(function (response) {
            if(response.data.status === 200){
                dispatch(success(response));
                dispatch(updateFutureScheduleState(currentState,response.data.result));
                SuccessModal(response.data.message);
            }else{
                ErrorModal(response.data);  
            } 
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: fScheduleConstants.SAVE_FSCHEDULE_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: fScheduleConstants.SAVE_FSCHEDULE_SUCCESS, response } }
    function failure(error: any) { return { type: fScheduleConstants.SAVE_FSCHEDULE_FAILURE, error } }
}

function updateFutureScheduleState(reqArr:Array<any>,resObj:any) {
     if(reqArr.length && resObj){
           let schedule = reqArr.find(elem=> elem.orderDay === resObj.orderDay);
           if(schedule){
             schedule.dsId= resObj.dsId; 
             schedule.blockNavigation=false;
           }
     }
     reqArr=SortWeekDays(reqArr, 'orderDay');
     return { type: fScheduleConstants.GET_FSCHEDULE_SUCCESS, response:{data:{result:reqArr}} };
}

function removeScheduleDay(dsId:number,currentState:Array<any>){
    return (dispatch:Function) => {
        fetch({
            method: 'put',
            url: 'futureSchedule/remove/'+dsId, 
        }).then(function (response) {
            if(response.data.status === 204){
                dispatch(updateRemoveScheduleState(currentState,dsId));
            }else{
                ErrorModal(response.data.message);  
            }
        }).catch(error=>{
            ErrorModal(error);
        });
      
    };
}

function updateRemoveScheduleState(arr:Array<any>,dsId:number) {
    let schedules =[];
    if(arr.length && dsId){
          schedules = arr.filter(elem=> elem.dsId !== dsId);
    }
    return { type: fScheduleConstants.GET_FSCHEDULE_SUCCESS, response:{data:{result:schedules}} }; 
}