const storeUser = ['report', 'matrix'];
const districtUser = ['report', 'matrix', 'search'];
const storeUserMenu = ["OSM", 'REPORT'];
const districtUserMenu = ["ODT", "OSM", 'REPORT'];

const getRoleAccess = (role: string) => {
    let arr = getUserInfo();
    if (arr.includes('OktaApp-CAO-Dash-Admin')) {
        return true;
    } else if (arr.includes('OktaApp-CAO-Dash-Store')) {
        if (storeUser.includes(role)) {
            return true;
        } else {
            return false;
        }
    } else if (arr.includes('OktaApp-CAO-Dash-District')) {
        if (districtUser.includes(role)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

const getUserRole = () => {
    let arr = getUserInfo();
    if (arr.includes('OktaApp-CAO-Dash-Admin')) {
        return 'Admin';
    } else if (arr.includes('OktaApp-CAO-Dash-Store')) {
        return 'Store';
    } else if (arr.includes('OktaApp-CAO-Dash-District')) {
        return 'District';
    } else {
        return '-';
    }
}


const getUserInfo = () => {
    const userInfo = localStorage.getItem('userInfo');
    // working with groups
    //let arr = userInfo ? (JSON.parse(userInfo).groups) ? JSON.parse(userInfo).groups:[] : [];
    let arr = userInfo ? (JSON.parse(userInfo).groups) ? JSON.parse(userInfo).groups:['OktaApp-CAO-Dash-Admin'] : ['OktaApp-CAO-Dash-Admin'];
    return arr;
}

const getLandPageAccess = (menu: string) => {
    let arr = getUserInfo();
    if (arr.includes('OktaApp-CAO-Dash-Admin')) {
        return true;
    } else if (districtUserMenu.includes(menu) && arr.includes('OktaApp-CAO-Dash-District')) {
        return true;
    } else if (arr.includes('OktaApp-CAO-Dash-Store') && storeUserMenu.includes(menu)) {
        return true;
    } else {
        return false;
    }
}
export { getRoleAccess, getUserRole,getLandPageAccess };