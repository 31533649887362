import { useState, useRef, useEffect } from 'react';
import { ConfirmModal, DateTimePicker, ScheduleDayModal } from "../../../components";
import { useSelector, useDispatch } from 'react-redux';
import { scheduleActions } from '../../../actions';
import { messageConstants, weekDays } from '../../../constants';
import { NewScheduleInterface } from '../ScheduleInterfaces';
import moment from 'moment';
import { SortWeekDays } from '../../../hoc';
import { ScheduleDay } from '../utils/ScheduleDay';
import { Prompt } from 'react-router';
import { isChanged, isUnsavedNewSchedule } from '../utils/ScheduleUtils';

const NewSchedulePage = (props: any) => {
    const storeId = props.store.storeId;
    const vendorId = props.vendor.vendorId;
    const { result } = useSelector((state: any) => state.schedule);
    const childRef: any = useRef([]);
    const dispatch = useDispatch();
    const [weekSchedules, setWeekSchedules] = useState<NewScheduleInterface[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [errors, setErrors] = useState([]);
    const [warnings, setWarnings] = useState([]);
    const [scheduleDayModal, setScheduleDayModal] = useState({ items: [], title: "", type: "", show: false });
    const [effectiveDate, setEffectiveDate] = useState("");
    const minDate = new Date();
    minDate.setDate((new Date()).getDate() + 2);
    useEffect(() => {
    	if(result){
    	    setWeekSchedules(JSON.parse(JSON.stringify(result)));
    	    if (result.length) {
            	let effDate: any = new Date(result[0]['effectiveDate']);
            	setEffectiveDate(effDate);
        	}
    	}
        
    }, [result]);

    useEffect(() =>{
        setErrors([]);
        setWarnings([]);
        setWeekSchedules([]); 
        setEffectiveDate(""); 
    },[storeId,vendorId])

    const toggleAccordion = (index: number) => {
        setActiveIndex(index);
    }


    const onAddDay = () => {
        let days: any = weekDays.filter(val => !weekSchedules.map((row: any) => row['orderDay']).includes(val));
        setScheduleDayModal({ items: days, type: "Create", title: "Add New Schedule Day", show: true });
        document.body.classList.add('modal-schedule');

    }
    const onRemoveDay = () => {
        let days: any = weekSchedules.map((row: any) => row['orderDay']);
        setScheduleDayModal({ items: days, type: "Remove", title: "Remove Schedule Day", show: true });
        document.body.classList.add('modal-schedule');
    }

    const onAddRemoveDay = (day: any) => {
        document.body.classList.remove('modal-schedule');
        setScheduleDayModal(state => ({ ...state, show: false }));
        if (day) {
            if (scheduleDayModal.type === 'Remove') {
                let data = weekSchedules.filter((val: any) => val.orderDay !== day);
                setWeekSchedules(data);
                if(data.length === 0 ){
                    setEffectiveDate("");
                }

            } else {
                let newSchedule: NewScheduleInterface = {
                    dsId: 0,
                    orderDay: day,
                    orderCutoffTime: "00:00",
                    deliveryDay: "Sun",
                    deliveryWindowBegin: "00:00",
                    deliveryWindowEnd: "00:00",
                    effectiveDate: effectiveDate ?  moment(new Date(effectiveDate)).format('MM-DD-YYYY').replaceAll("-", "/"):"",
                    onShelfDay: "Sun",
                    onShelfHours: 8,
                    createLeadTime: 18,
                    deliveryHour: 24,
                }
                let data: any = [...weekSchedules];
                data.push(newSchedule);
                const sortedArr = SortWeekDays(data, 'orderDay');
                setWeekSchedules(sortedArr);
            }
        }
    }

    const saveSchedule = () => {
        let valid: boolean = true;
        let errorDays: any = [];
        let warningDays:any = [];
        if (childRef.current) {
            childRef.current.forEach((row: any, index: number) => {
                if (childRef.current[index]) {
                    let {isValid,warnings} = childRef.current[index].validateChild();
                    if (!isValid) {
                        valid = false;
                        errorDays.push(weekSchedules[index]['orderDay']);
                    }
                    if(Object.keys(warnings).length !== 0){
                        warningDays.push(weekSchedules[index]['orderDay']);
                    }
                }
            })

        }
        if (!effectiveDate && weekSchedules.length) {
            errorDays.push("Effective Date must be provided.");
        }
        setErrors(errorDays);
        setWarnings(warningDays); 
        if (valid && errorDays.length === 0) {
            let schedules: any = [...weekSchedules];

            if(!result || typeof result == "undefined"){
                let result = [];
            }
            let delRows = result.filter((el: any) => {
                return !schedules.find((obj: any) => {
                    return el.dsId === obj.dsId;
                });
            });
            delRows.forEach((row: any) => { row['deleteFlag'] = 1; });
            const concatArr = schedules.concat(delRows);
            if(concatArr.length){
                ConfirmModal({message:messageConstants.SAVE_SCHEDULE_CONFIRM,confirm:"Yes",cancel:"No",onValueChange:function onValueChange(confirm:any){
                    if(confirm){
                        dispatch(scheduleActions.saveNewSchedule(concatArr, storeId, vendorId));
                        setWarnings([]);
                    }
                }});
            }
        }
    }


    const handleChange = (e: any, index: number,key?:string) => {
        let cloneArr: Array<any> = [...weekSchedules];
        if(key){
            cloneArr[index][key] = e;
        }else{
            const { name, value } = e.target;
            const numProtoTypeCols = ["onShelfHours", "deliveryHour", "createLeadTime"];
            if (numProtoTypeCols.includes(name)) {
                cloneArr[index][name] = Number(value);
            } else {
                cloneArr[index][name] = value;
            }
        }
        setWeekSchedules(cloneArr);
    }

    const onEffectiveDateChange = (e: any) => {
        setEffectiveDate(e);
        let cloneArr= [...weekSchedules];
        cloneArr.forEach((row: any) => {
            row['effectiveDate'] = moment(new Date(e)).format('MM-DD-YYYY').replaceAll("-", "/");
        });
        setWeekSchedules(cloneArr);
    }

    const onClearChild = (index: number) => {
        let cloneArr: any = [...weekSchedules];
        cloneArr[index]['orderCutoffTime'] = '00:00';
        cloneArr[index]['deliveryDay'] = 'Sun'; 
        cloneArr[index]['deliveryWindowBegin'] = "00:00";
        cloneArr[index]['deliveryWindowEnd'] = "00:00";
        cloneArr[index]['onShelfDay'] = 'Sun';
        cloneArr[index]['onShelfHours'] = 8;
        cloneArr[index]['createLeadTime'] = 18;
        cloneArr[index]['deliveryHour'] = 24;
        setWeekSchedules(cloneArr);
    }
    return (
        <div>
            <div className="title-sec">
                <h3 className="m-0">New / Edit Schedule </h3>
                <div className="title-right">
                    <div className="d-inline-block me-3">
                        <label className="d-block form-label">Effective Date</label>
                        <DateTimePicker
                            disabled={weekSchedules.length === 0}
                            id="effectiveDate"
                            dateFormat="MM/dd/yyyy"
                            className="form-control date-control mb-0"
                            autoComplete="off"
                            onChange={onEffectiveDateChange}
                            minDate={minDate}
                            onKeyDown={(e: any) => e.preventDefault()}
                            selected={effectiveDate} />

                    </div>
                    <button type="button" className="btn btn-primary" id="save-btn" onClick={saveSchedule} disabled={!isChanged(weekSchedules,result)}>Save</button>
                </div>
            </div>
            <div className="accordion m-3" id="schedule">
                {errors.length > 0 && <span className="error-toast mt-2">Please validate: {errors.toString()}</span>}
                {warnings.length > 0 && <span className="warn-toast mt-2">Warning Days: {warnings.toString()}</span>}

                {weekSchedules.length === 0 && <h6 className="text-center mt-4 nodata-cont">No Data Available</h6>}
                {weekSchedules && weekSchedules.map((row: any, index: number) => {
                    let scheduledDays = weekDays.filter((val: any) => !weekSchedules.map((row: any) => row['orderDay']).includes(val));
                    scheduledDays.push(row.orderDay);
                    return (
                        <div className="accordion-item" id={"heading" + (index + 1)} key={index}>
                            <h2 className="accordion-header" id={"heading" + (index + 1)} onClick={() => toggleAccordion(index)}>
                                <button className={(index === activeIndex) ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + (index + 1)} aria-expanded={(index === activeIndex) ? "true" : "false"} aria-controls={"collapse" + (index + 1)}>
                                    {row.orderDay}
                                </button>
                            </h2>
                            <div id={"collapse" + (index + 1)} className={(index === activeIndex) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading" + (index + 1)} data-bs-parent="#schedule">
                                <div className="accordion-body">
                                    <ScheduleDay key={index} data={row} items={scheduledDays} index={index} ref={(elem) => { childRef.current[index] = elem; }} onChange={handleChange} onClear={onClearChild} storeId={storeId} vendorId={vendorId} />
                                </div>
                            </div>
                        </div>)
                })}

            </div>
            <div className="text-end mx-3">
                <button type="button" id="remove-day" className="btn btn-secondary me-3 mb-3" onClick={onRemoveDay} disabled={weekSchedules.length === 0}>- Remove a Schedule day</button>
                <button type="button" id="add-day" className="btn btn-secondary mb-3" onClick={onAddDay} disabled={weekSchedules.length === 7 || !storeId || !vendorId}>+ Add a Schedule day</button>
            </div>
            {scheduleDayModal.show && <ScheduleDayModal type={scheduleDayModal.type} value="" items={scheduleDayModal.items} title={scheduleDayModal.title} onAddRemoveDay={onAddRemoveDay} />}
            <Prompt
                when={isUnsavedNewSchedule(weekSchedules,result)}
                message={messageConstants.PAGE_LEAVE_WARN}
            />
        </div>
    )
}

export { NewSchedulePage };

