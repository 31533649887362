import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { vendorStoreActions } from "../../actions";
import { DropDown } from "../../components";
import { generateKey } from "../../hoc";
import { CurrentSchedulePage } from "./CurrentSchedulePage";
import { NewSchedulePage } from "./new-schedule/NewSchedulePage";
import { StoreInterface, VendorInterface } from './ScheduleInterfaces';
import { FutureSchedulePage } from './future-schedule/FutureSchedulePage';

const ScheduleHomePage = () => {
    const storeList: StoreInterface[] = useSelector((state: any) => state.store.storeList);
    const vendorList: VendorInterface[] = useSelector((state: any) => state.vendor.vendorList);

    const [store, setStore] = useState({ storeId: "" });
    const [vendor, setVendor] = useState({ vendor: "", vendorId: "" });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(vendorStoreActions.getStoreList());
        dispatch(vendorStoreActions.getVendorList());
    }, [dispatch]);

    // useEffect(() => {
    //     if (storeList && storeList.length && vendorList && vendorList.length) {
    //         setStore(storeList[0]);
    //         setVendor(vendorList[0]);
    //     }
    // }, [storeList, vendorList]);

    const changeStore = (e: any) => {
        setStore(e);
    }

    const changeVendor = (e: any) => {
        setVendor(e);
    }

    return (
        <div className="tab-content">
            <div className="headding-cont">
                <h2 className="d-inline-block">Weekly Schedule</h2>
                {/* <button type="button" className="btn-small clone-btn">Clone</button> */}
                <p>Store/vendor’s schedule</p>
            </div>
            <div className="intra-cont">
                <div className="form-cont">
                    <div className="d-inline-block me-3">
                        <label className="form-label">Store</label>
                        <DropDown key={generateKey()} id="store" options={storeList} label="storeId" value={store.storeId ? store:""} onChange={changeStore}></DropDown>
                    </div>
                    <div className="d-inline-block">
                        <label className="form-label">Vendor</label>
                        <DropDown key={generateKey()} id="vendor" options={vendorList} label="vendor" value={vendor.vendorId ? vendor:""} onChange={changeVendor}></DropDown>
                    </div>
                </div>
                {/* <StaticOptionsPage /> */}
            </div>

            <div className="round-cont">
                <div className="schdule-tabs">
                    <nav className="schdule-nav">
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-1-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1"
                                aria-selected="true">Current Schedule</button>
                            <button className="nav-link" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2"
                                type="button" role="tab" aria-controls="nav-2" aria-selected="false">New
                                Schedule</button>
                            <button className="nav-link" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-3"
                                type="button" role="tab" aria-controls="nav-3" aria-selected="false">Future
                                Schedule</button>
                            { false && <button className="nav-link" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-4"
                                type="button" role="tab" aria-controls="nav-4"
                                aria-selected="false">History</button> }
                        </div>
                    </nav>
                </div>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                        <CurrentSchedulePage store={store} vendor={vendor} />
                    </div>
                    <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                        <NewSchedulePage store={store} vendor={vendor} />
                    </div>
                    <div className="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
                        <FutureSchedulePage store={store} vendor={vendor} />
                    </div>
                    <div className="tab-pane fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab">
                        ...coming soon
                    </div>
                </div>
            </div>
        </div>
    )

}

export { ScheduleHomePage };