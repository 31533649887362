const messageConstants={
    LOGOUT_WARN:"Are you sure you want to logout?",
    SAVE_SCHEDULE_CONFIRM:"Are you sure you want to save the changes?",
    SAVE_SCHEDULE_CONFIRM_WITH_WARN:"Are you sure you want to save the changes with warnings?",
    DELETE_HOLIDAY_CONFIRM:"Are you sure you want to delete it permanently? Deleting this holiday will also delete all schedules associated with it.",
    UPDATE_HOLIDAY_CONFIRM:"Are you sure you want to update?",
    PUBLISH_HOLIDAY_CONFIRM:"Are you sure you want to publish?",
    ACTIVATE_HOLIDAY_CONFIRM:"Are you sure you want to activate?",
    VALID_HOLIDAY_NAME:"Please enter valid schedule name and year. e.g: <holiday> - <yyyy>.",
    VALID_HOLIDAY_YEAR:"Please enter current or future year.",
    PAGE_LEAVE_WARN:"Changes are not saved. Do you want to continue?",
    EFF_DATE_RANG_ERR:"Effective date range must be limited to seven days.",
}

const warningConstants={
    DELIVERYDAYSAME:"Order day and Delivery day are the same day.",
    DONOTCONSECUTIVE:"Order day and Delivery day are not consecutive.",
    ONSHELFDAYSAME:"Delivery Day is on or after On Shelf Day.",
    ONSHELFNOTCONSECUTIVE:"Delivery day and On Shelf day are not consecutive.",
}

export {messageConstants,warningConstants};