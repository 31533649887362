
import { AxiosResponse } from 'axios';
import { userConstants } from '../constants';


export const userActions = {
    storeUserInfo
};

function storeUserInfo(userInfo:any,errorInfo?:any) {
    return (dispatch:Function) => {
         if(userInfo){
            dispatch(success(userInfo));
         }else if(errorInfo){
            dispatch(failure(errorInfo));
         }else{
            dispatch(request());
         }  
    };
    function request() { return { type: userConstants.GET_USERINFO_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: userConstants.GET_USERINFO_SUCCESS, response } }
    function failure(error: any) { return { type: userConstants.GET_USERINFO_FAILURE, error } }
}
