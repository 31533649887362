import './Style.css';
import confirmIcon from '../../assets/images/ic_error.svg';

declare var bootbox: any;
const ConfirmModal = (props: any) => {
    bootbox.confirm({
        message: `<div  class="status-cont text-center mb-4"><img class="rounded mx-auto d-block" width="72" src=`+confirmIcon+` alt="success"/></div>
        <h6 class="model-text text-secondary text-center m-0">`+props.message+`</h6>`,
        buttons: {       
            confirm: {
                label: props.confirm,
                className: 'btn btn-primary ripple'
            },
            cancel: {
                label: props.cancel,
                className: 'btn btn-outline-secondary'
            }
        },
        callback: function (result: any) {
            props.onValueChange(result);
        }
    });
};



export { ConfirmModal };
