import { useState, useEffect } from 'react';
import { ScheduleDayModal, Select } from "../../../components";
import { useDispatch, useSelector } from 'react-redux';
import { messageConstants, weekDays } from '../../../constants';
import { NewScheduleInterface } from '../ScheduleInterfaces';
import { SortWeekDays } from '../../../hoc';
import { fscheduleActions } from '../../../actions';
import { ScheduleDay } from '../utils/ScheduleDay';
import { blockNavigation } from '../utils/ScheduleUtils';
import { Prompt } from 'react-router';

const FutureSchedulePage = (props: any) => {
    const storeId = props.store.storeId;
    const vendorId = props.vendor.vendorId;
    const { result, holidayList } = useSelector((state: any) => state.fschedule);
    const [weekSchedules, setWeekSchedules] = useState<NewScheduleInterface[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [scheduleDayModal, setScheduleDayModal] = useState({ items: [], title: "", type: "", show: false });
    const [state, setState] = useState({ effectiveDate: "", endDate: "", holidayId: 0, status: "" });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fscheduleActions.getFutureHolidayList());
    }, [dispatch]);


    useEffect(() => {
        if (state.holidayId > 0 && storeId && vendorId) {
            dispatch(fscheduleActions.getFutureSchedule(storeId, vendorId, state.holidayId));
        }
    }, [dispatch, state.holidayId, storeId, vendorId]);


    useEffect(() => {
        if (result && result.length && vendorId && storeId && state.holidayId) {
            setWeekSchedules(result);
        } else {
            setWeekSchedules([]);
        }

    }, [result, state.holidayId, storeId, vendorId]);

    const toggleAccordion = (index: number) => {
        setActiveIndex(index);
    }

    const onAddDay = () => {
        let days: any = weekDays.filter(val => !weekSchedules.map((row: any) => row['orderDay']).includes(val));
        setScheduleDayModal({ items: days, type: "Create", title: "Add New Schedule Day", show: true });
        document.body.classList.add('modal-schedule');

    }
    const onRemoveDay = () => {
        let days: any = weekSchedules.map((row: any) => row['orderDay']);
        setScheduleDayModal({ items: days, type: "Remove", title: "Remove Schedule Day", show: true });
        document.body.classList.add('modal-schedule');
    }

    const onAddRemoveDay = (day: any) => {
        document.body.classList.remove('modal-schedule');
        setScheduleDayModal(state => ({ ...state, show: false }));
        if (day) {
            if (scheduleDayModal.type === 'Remove') {
                let removeData: any = weekSchedules.find((val: any) => val.orderDay === day)
                if (Number(removeData.dsId) === 0) {
                    let data: any = weekSchedules.filter((val: any) => val.orderDay !== day);
                    setWeekSchedules(data);
                } else {
                    dispatch(fscheduleActions.removeScheduleDay(removeData.dsId, weekSchedules));
                }
            } else {
                let futSchedule: NewScheduleInterface = {
                    dsId: 0,
                    orderDay: day,
                    orderCutoffTime: "00:00",
                    deliveryDay: "Sun",
                    deliveryWindowBegin: "00:00",
                    deliveryWindowEnd: "00:00",
                    effectiveDate: "",
                    onShelfDay: "Sun",
                    onShelfHours: 8,
                    createLeadTime: 18,
                    deliveryHour: 24,
                    endDate: "",
                    holidayId: 0,
                    blockNavigation: true
                }
                let data: any = [...weekSchedules];
                data.push(futSchedule);
                const sortedArr = SortWeekDays(data, 'orderDay');
                setWeekSchedules(sortedArr);
            }
        }
    }

    const handleChange = (e: any, index: number, key?: string) => {
        let cloneArr: Array<any> = [...weekSchedules];
        if (key) {
            cloneArr[index][key] = e;
        } else {
            const { name, value } = e.target;
            const numProtoTypeCols = ["onShelfHours", "deliveryHour", "createLeadTime"];
            if (numProtoTypeCols.includes(name)) {
                cloneArr[index][name] = Number(value);
            } else {
                cloneArr[index][name] = value;
            }
        }
        cloneArr[index]['blockNavigation'] = true;
        setWeekSchedules(cloneArr);
    }


    const handleHolidayChange = (e: any) => {
        const { value } = e.target;
        let data = holidayList.find((row: any) => row.holidayId === Number(value));
        setState({ effectiveDate: data.effectiveDate, endDate: data.endDate, holidayId: data.holidayId, status: data.status });
    }

    const onClearChild = (index: number) => {
        let cloneArr: any = [...weekSchedules];
        let initialState: NewScheduleInterface = {
            dsId: cloneArr[index]['dsId'],
            orderDay: cloneArr[index]['orderDay'],
            orderCutoffTime: "00:00",
            deliveryDay: "Sun",
            deliveryWindowBegin: "00:00",
            deliveryWindowEnd: "00:00",
            effectiveDate: "",
            onShelfDay: "Sun",
            onShelfHours: 8,
            createLeadTime: 18,
            deliveryHour: 24,
            endDate: "",
            holidayId: 0,
            blockNavigation: true
        }
        cloneArr[index] = initialState;
        setWeekSchedules(cloneArr);
    }

    return (
        <div>
            <div className="title-sec">
                <h3 className="m-0">Future Schedule </h3>
                <div className="title-right align-items-center">
                    <div className="title-right align-items-center">
                        <p className="text-title mb-0 me-3">Effective Date: <span className="text-primary">{state.effectiveDate ? state.effectiveDate : "--/--/----"}</span></p>
                        <p className="text-title mb-0 me-3">End Date: <span className="text-primary">{state.endDate ? state.endDate : "--/--/----"}</span></p>
                    </div>
                    <div className="d-inline-block">
                        <div className="label">
                            <label>Holiday Schedule</label>
                        </div>
                        <Select name="holidayId" label="namedSchedule" defaultLabel="Select Holiday" labelValue="holidayId" items={holidayList} value={state.holidayId} onChange={handleHolidayChange} />
                    </div>
                </div>
            </div>
            <div className="accordion m-3" id="schedule">
                {weekSchedules.length === 0 && <h6 className="text-center nodata-cont">No Data Available</h6>}
                {weekSchedules && weekSchedules.map((row: any, index: number) => {
                    let scheduledDays = weekDays.filter((val: any) => !weekSchedules.map((row: any) => row['orderDay']).includes(val));
                    scheduledDays.push(row.orderDay);
                    return (
                        <div className="accordion-item" id={"heading" + (index + 1)} key={index}>
                            <h2 className="accordion-header" id={"heading" + (index + 1)} onClick={() => toggleAccordion(index)}>
                                <button className={(index === activeIndex) ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + (index + 1)} aria-expanded={(index === activeIndex) ? "true" : "false"} aria-controls={"collapse" + (index + 1)}>
                                    {row.orderDay}
                                </button>
                            </h2>
                            <div id={"collapse" + (index + 1)} className={(index === activeIndex) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading" + (index + 1)} data-bs-parent="#schedule">
                                <div className="accordion-body">
                                    <ScheduleDay key={index} data={row} items={scheduledDays} index={index} onChange={handleChange} onClear={onClearChild} storeId={storeId} vendorId={vendorId} holidayData={state} currentState={weekSchedules} />
                                </div>
                            </div>
                        </div>)
                })}

            </div>
            <div className="text-end mx-3">
                <button type="button" className="btn btn-secondary me-3 mb-3" id="remove-day" onClick={onRemoveDay} disabled={weekSchedules.length === 0}>- Remove a Schedule day</button>
                <button type="button" className="btn btn-secondary mb-3" id="add-day" onClick={onAddDay} disabled={(weekSchedules.length === 7 || state.holidayId === 0 || !storeId || !vendorId)}>+ Add a Schedule day</button>
            </div>
            {scheduleDayModal.show && <ScheduleDayModal type={scheduleDayModal.type} value="" items={scheduleDayModal.items} title={scheduleDayModal.title} onAddRemoveDay={onAddRemoveDay} />}
            <Prompt
                when={blockNavigation(weekSchedules)}
                message={messageConstants.PAGE_LEAVE_WARN}            />
        </div>
    )
}

export { FutureSchedulePage };


