import { searchMatrixConstants } from "../constants";

const initialState = {
    searchResult: [],
    matrixResult:[]
};

export function searchMatrix(state = initialState, action: any) {
    switch (action.type) {
        case searchMatrixConstants.GET_SEARCH_REQUEST:
            return { ...state,searchResult:[] };
        case searchMatrixConstants.GET_SEARCH_SUCCESS:
            return {
                ...state,
                searchResult: action.response.data.result
            };
        case searchMatrixConstants.GET_SEARCH_FAILURE:
            return { ...state };
        case searchMatrixConstants.GET_MATRIX_REQUEST:
            return { ...state,matrixResult:[] };
        case searchMatrixConstants.GET_MATRIX_SUCCESS:
            return {
                ...state,
                matrixResult: groupBy(action.response.data.result,'department')
            };
        case searchMatrixConstants.GET_MATRIX_FAILURE:
            return { ...state };
        default:
            return state
    }
}


const groupBy=(arr:Array<any>, criteria:string) =>{
    let result:Array<any> = []
    if(!arr){
        return result;
    }
    arr.forEach(function (currentValue) {
        let obj = findElem(result,currentValue[criteria]);
        if (!obj) {
            result.push({
                department: currentValue[criteria],
                list: []
            });
        }
        
        if (obj) {
            obj.list.push(currentValue);
        }else{
            let newObj = findElem(result,currentValue[criteria]);
            newObj.list.push(currentValue);
        }
    });
    return result;
}

const findElem=(arr:Array<any>,value:string)=>{
    return arr.find((row:any) => {
        return row.department === value;
    });
}
