import moment from 'moment';
import XLSX from 'xlsx-js-style';
import { generateKey } from '../../hoc';
const Days: Array<any> = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const headerCellStyle = {
    fill: { fgColor: { rgb: "C9947E" } },
    font: { name: 'Arial', sz: 12, bold: true,color:{rgb:"FFFFFF"} },
    alignment: { wrapText: true, vertical: 'bottom', horizontal: 'center' }
}
const subHeaderCellStyle = {
    font: { name: 'Arial', sz: 10, bold: true },
    alignment: { wrapText: true, vertical: 'bottom', horizontal: 'center' }
}
const cellStyle = {
    font: { name: 'Arial', sz: 10, bold: false },
    alignment: { wrapText: true, vertical: 'bottom' }
}
const deptCellStyle = {
    fill: { fgColor: { rgb: "F0E5DF" } },
    font: { name: 'Arial', sz: 12, bold: true,color:{rgb:"B43B3C"} },
    alignment: { wrapText: true, vertical: 'bottom' }
}

const onExport = () => {
    let doc: any = document.getElementById("matrix-tbl");
    var wb: any = XLSX.utils.table_to_book(doc, { cellStyles: true });
    wb.Sheets.Sheet1['!cols'] = [{ width: 21 }, { width: 11 }, { width: 8 }, { width: 11 }, { width: 8 }, { width: 11 }, { width: 8 }, { width: 11 }, { width: 8 }, { width: 11 }, { width: 8 }, { width: 11 }, { width: 8 }, { width: 11 }, { width: 8 }, { width: 11 }];
    let range = XLSX.utils.decode_range(wb.Sheets.Sheet1['!ref']);
    let i = 0;
    for (let C = range.s.c; C <= range.e.c; ++C) {
        i = 0;
        while (i < 2) {
            const cell = XLSX.utils.encode_cell({ c: C, r: i });
            if (wb.Sheets.Sheet1[cell]) {
                if(i===0){
                    wb.Sheets.Sheet1[cell].s = headerCellStyle;
                }else{
                    wb.Sheets.Sheet1[cell].s = subHeaderCellStyle;
                }
            }
            i++;
        }
        if (i >= 2) {
            while (i < (range.e.r) + 2) {
                const cell = XLSX.utils.encode_cell({ c: C, r: i });
                if (wb.Sheets.Sheet1[cell]) {
                    wb.Sheets.Sheet1[cell].s = cellStyle;
                }
                i++;
            }
        }
    }

    const mergeCols = wb.Sheets.Sheet1['!merges'];
    mergeCols.forEach((elm: any, index: number) => {
        const {e}=elm;
        if (index >=7) {
            const cell = XLSX.utils.encode_cell({ c: 0, r: e.r });
            if (wb.Sheets.Sheet1[cell]) {
                wb.Sheets.Sheet1[cell].s = deptCellStyle;
            }
        }
    });
    const fileName=moment(new Date()).format('MM-DD-YYYY')+"_MasterScheduleMatrix.xlsx";

    XLSX.writeFile(wb, fileName, {bookType:"xlsx", cellStyles: true });
}



const filterMatrixList = (arr: Array<any>, dept: Array<any>) => {
    let depts = dept.map((ele: any) => ele.majorDepartment);
    if (depts.includes("Select All")) {
        return arr;
    } else {
        return arr.filter((row) => {
            return depts.includes(row.department);
        });
    }
}
const groupItems = function (arr: any, key: string) {
    let list = arr.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
    return list;
};

const createDynamicRow = (item: any) => {
    return Object.keys(groupItems(item.list, 'vendor')).map((key: any) => {
        return (
            <tr key={generateKey()} className="product">
                {dynamicColumns(item.list, key)}
            </tr>)
    });
}
const dynamicColumns = (item: any, key: string) => {
    let arr = [<td key={generateKey()} width="120px">{key}</td>];
    let list = groupItems(item, 'vendor')[key];
    arr.push(<td key={generateKey()} width="120px">{list[0].transporter}</td>);
    Days.forEach((day, i) => {
        let data = list.find((elem: any) => elem.orderDay === day);
        arr.push(<td key={generateKey()} width="52px">{data && data.orderDay === day ? data.orderCutoffTime : ""}</td>);
        arr.push(<td key={generateKey()} width="100px">{data && data.orderDay === day ? data.delivery : ""}</td>);
    });
    return arr;
}

export { onExport, filterMatrixList, createDynamicRow };