const sorter: any = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6,
}

const sortCustom = ['Create', 'Publish', 'Active', 'Inactive', 'Deleted'];

const SortWeekDays = (arr: Array<any>, key: string) => {
    const result = SortArray(arr, key);
    return result;
}
const SortArray = (arr: Array<any>, key: string) => {

    return arr.sort(function (a, b) {
        return sorter[a[key]] - sorter[b[key]];
    });

}

const SortByDate = (arr: Array<any>, key: any) => {
    return moveRecordTotop(customSortByStatus(sortDates(arr, key), sortCustom, 'status'));
}

const sortDates = (arr: Array<any>, key: string) => {
    const distantFuture = new Date(8640000000000000);
    return arr.sort((a: any, b: any) => {
        let dateA = a[key] ? new Date(a[key]) : distantFuture;
        let dateB = b[key] ? new Date(b[key]) : distantFuture;
        return dateB.getTime() - dateA.getTime();
    });
}

const customSortByStatus = (data: Array<any>, sortBy: Array<any>, sortField: string) => {
    const sortByObject = sortBy.reduce((obj, item, index) => {
        return {
            ...obj,
            [item]: index
        }
    }, {})
    return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]])
}
const moveRecordTotop = (arr: Array<any>) => {
    return arr.sort(function (x: any, y: any) { return x['namedSchedule'] === "Master" ? -1 : y['namedSchedule'] === "Master" ? 1 : 0; });
}

export { SortWeekDays, SortByDate,sorter };