import { CurrentScheduleHeadings, CurrentScheduleKeys } from "../../../constants";
let cScheduleSaved: boolean = true;
let fScheduleSaved: boolean = true;

const CurrentScheduleTable = (props: any) => {
    let vHeader = CurrentScheduleHeadings;
    let keys: Array<string> = CurrentScheduleKeys;

    const { result, parentId, childId } = props;

    let myTableDiv = document.getElementById(parentId);

    if (myTableDiv) {
        let tableIdToRemove = document.getElementById(childId);
        if (tableIdToRemove && tableIdToRemove.parentNode != null) {
            tableIdToRemove.parentNode.removeChild(tableIdToRemove);
        }
        let table: any = document.createElement('TABLE');
        table.setAttribute("id", childId);
        table.setAttribute('width', '100%');
        table.setAttribute('height', '100%');
        table.setAttribute('class', 'table');

        let tableBody = document.createElement('TBODY');
        table.appendChild(tableBody);

        for (let i = 0; i < vHeader.length; i++) {
            let tr = document.createElement('TR');
            tableBody.appendChild(tr);
            let th = document.createElement('TH');
            th.style.width = '16%';
            if (vHeader[i] === 'Order Day') {
                th.style.backgroundColor = "#f0f0f0";
            }
            th.appendChild(document.createTextNode(vHeader[i]));
            tr.appendChild(th);
        }

        let rows: Array<any> = table.rows;

        for (let i = 0; i < 7; i++) {
            let cellCount = 0;
            if (result[i]) {
                for (let key of keys) {
                    let cell = document.createElement("td");
                    cell.style.width = '12%';
                    if (key === "orderDay") {
                        cell.style.fontWeight = 'bold';
                        cell.style.backgroundColor = "#f0f0f0";
                    }
                    let cellText: any;
                    if (key === 'onShelfDay') {
                        cellText = document.createTextNode(result[i][key] + "/" + result[i]['onShelfHours']);
                    } else if (key === 'deliveryDay') {
                        cellText = document.createTextNode(result[i][key] + "/" + result[i]['deliveryHour']);
                    } else {
                        cellText = document.createTextNode(result[i][key] || "-");
                    }
                    cell.appendChild(cellText);
                    rows[cellCount].appendChild(cell);
                    cellCount++;
                }
            } else {
                for (let key of keys) {
                    let cell = document.createElement("td");
                    cell.style.width = '12%';
                    let cellText: any;
                    if (key === "orderDay") {
                        cell.style.fontWeight = 'bold';
                        cell.style.backgroundColor = "#f0f0f0";
                        cellText = document.createTextNode("Day " + (i + 1));
                    } else {
                        cellText = document.createTextNode("-");
                    }
                    cell.appendChild(cellText);
                    rows[cellCount].appendChild(cell);
                    cellCount++;
                }
            }
        }
        myTableDiv.appendChild(table);
    }
}

const blockNavigation = (arr: Array<any>) => {

    let list = arr.filter(row => {
        return row.blockNavigation && row.blockNavigation === true;
    });
    let isBlock = false;
    if (list.length > 0) {
        window.onbeforeunload = () => true;
        fScheduleSaved = false;
        isBlock=true;
    } else {
        window.onbeforeunload = () => undefined;
        fScheduleSaved = true;
        isBlock=false;
    }
    if(!cScheduleSaved){
        window.onbeforeunload = () => undefined;
        isBlock=true;
    }
    return isBlock;
}

const isUnsavedNewSchedule = (arr: Array<any>, oldArr: Array<any>) => {
    let isBlock = false;
    if (isChanged(arr,oldArr)) {
        window.onbeforeunload = () => true;
        cScheduleSaved = false;
        isBlock = true;
    } else {
        cScheduleSaved = true;
        window.onbeforeunload = () => undefined;
        isBlock = false;
    }
    if(!fScheduleSaved){
        window.onbeforeunload = () => undefined;
        isBlock=true;
    }   
    return isBlock;
}

const isChanged = (arr: Array<any>, oldArr: Array<any>) => {
    return (JSON.stringify(oldArr) !== JSON.stringify(arr)) ? true : false
}






export { CurrentScheduleTable, blockNavigation, isUnsavedNewSchedule, isChanged }