import { fScheduleConstants } from "../constants";
const initialState = {
    result: [],
    fSchedule: [],
    holidayList: []
};

export function fschedule(state = initialState, action: any) {
    switch (action.type) {
        case fScheduleConstants.GET_FSCHEDULE_REQUEST:
            return { ...state };
        case fScheduleConstants.GET_FSCHEDULE_SUCCESS:
            return {
                ...state,
                result: action.response.data.result
            };
        case fScheduleConstants.GET_FSCHEDULE_FAILURE:
            return { ...state };
        case fScheduleConstants.SAVE_FSCHEDULE_REQUEST:
            return { ...state };
        case fScheduleConstants.SAVE_FSCHEDULE_SUCCESS:
            return {
                ...state,
                fSchedule: action.response.data.result
            };
        case fScheduleConstants.SAVE_FSCHEDULE_FAILURE:
            return { ...state };
        case fScheduleConstants.GET_FHOLIDAY_REQUEST:
            return { ...state };
        case fScheduleConstants.GET_FHOLIDAY_SUCCESS:
            return {
                ...state,
                holidayList: action.response.data.result || []
             };
        case fScheduleConstants.GET_FHOLIDAY_FAILURE:
            return { ...state };
        default:
            return state
    }
}