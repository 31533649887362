import { userConstants } from "../constants";
let UserInfo = localStorage.getItem('userInfo');
const initialState = {
   userInfo: UserInfo ? JSON.parse(UserInfo):{}
};

export function user(state = initialState, action: any) {
    switch (action.type) {
        case userConstants.GET_USERINFO_REQUEST:
            return { ...state };
        case userConstants.GET_USERINFO_SUCCESS:
            return {
                ...state,
                userInfo: action.response
            };
        case userConstants.GET_USERINFO_FAILURE:
            return { ...state };
        default:
            return state;
    }
}