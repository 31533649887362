const checkUnsavedChanges = (arr: Array<any>) => {
    let list = arr.filter((row: any) => row.isSaved === false);
    if (list.length > 0) {
        window.onbeforeunload = () => true;
        return true;
    } else {
        window.onbeforeunload = () => undefined;
        return false;
    }
}
export { checkUnsavedChanges };