
import { AxiosResponse } from 'axios';
import { ErrorModal, SuccessModal } from '../components';
import { vendorStoreConstants } from '../constants';
import {fetch} from '../hoc';


export const vendorStoreActions = {
    getStoreList,
    getVendorList,
    getMajorDept,
    getDistrictList,
    getStoresByDistrict,
    getMinorDept,
    getSourceList,
    relaunchHPCSService
};

function getStoreList() {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'stores'   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: vendorStoreConstants.GET_STORE_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: vendorStoreConstants.GET_STORE_SUCCESS, response } }
    function failure(error: any) { return { type: vendorStoreConstants.GET_STORE_FAILURE, error } }
}

function getVendorList() {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'vendors'   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: vendorStoreConstants.GET_VENDOR_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: vendorStoreConstants.GET_VENDOR_SUCCESS, response } }
    function failure(error: any) { return { type: vendorStoreConstants.GET_VENDOR_FAILURE, error } }
}

function getMajorDept() {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'vendors?type=majorDepartments'   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: vendorStoreConstants.GET_VDEPT_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: vendorStoreConstants.GET_VDEPT_SUCCESS, response } }
    function failure(error: any) { return { type: vendorStoreConstants.GET_VDEPT_FAILURE, error } }
}

function getDistrictList() {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'districts'   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: vendorStoreConstants.GET_SDIST_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: vendorStoreConstants.GET_SDIST_SUCCESS, response } }
    function failure(error: any) { return { type: vendorStoreConstants.GET_SDIST_FAILURE, error } }
}

function getStoresByDistrict(district:string) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'stores?district='+district   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: vendorStoreConstants.GET_SBYDIST_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: vendorStoreConstants.GET_SBYDIST_SUCCESS, response } }
    function failure(error: any) { return { type: vendorStoreConstants.GET_SBYDIST_FAILURE, error } }
}

function getMinorDept(majorDept:string) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'vendorInfo/'+majorDept+'?type=minorDepartments'   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: vendorStoreConstants.GET_MIDEPT_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: vendorStoreConstants.GET_MIDEPT_SUCCESS, response } }
    function failure(error: any) { return { type: vendorStoreConstants.GET_MIDEPT_FAILURE, error } }
}

function getSourceList(majorDept:string) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'vendorInfo/'+majorDept+'?type=source'   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: vendorStoreConstants.GET_SOURCE_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: vendorStoreConstants.GET_SOURCE_SUCCESS, response } }
    function failure(error: any) { return { type: vendorStoreConstants.GET_SOURCE_FAILURE, error } }
}

function relaunchHPCSService(storeId:string) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'zena/trigger',
            data:{
                "process": {
                    "name": "WH_B_ITASCA_SERVICE_RESTART_HPCS",
                    "variables": {
                        "StoreList": "mag" + storeId + "," + storeId
                    }
                }
            }  
        }).then(function (response) {
            dispatch(success(response));
            SuccessModal("Restart successfully initated. Progress can be monitored in Zena-TEST.");
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        }); 
    };
    function request() { return { type: vendorStoreConstants.GET_SOURCE_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: vendorStoreConstants.GET_SOURCE_SUCCESS, response } }
    function failure(error: any) { return { type: vendorStoreConstants.GET_SOURCE_FAILURE, error } }
}
