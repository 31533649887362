import './Style.css';

declare var bootbox: any;
const ConfirmWithDataModal = (props: any) => {
    const {namedSchedule,effectiveDate,endDate}= props.data;
    bootbox.confirm({
        message: `
        <h5 class="model-text text-secondary text-center m-0">`+props.message+`</h5>
        <div class="d-flex flex-column mt-3"><span class="m-1">Schedule: `+namedSchedule+`</span><span class="m-1">Effective Date: `+effectiveDate+
        `</span><span class="ms-1">End Date: `+endDate+`</span>`,
        buttons: {       
            confirm: {
                label: props.confirm,
                className: 'btn btn-primary ripple'
            },
            cancel: {
                label: props.cancel,
                className: 'btn btn-outline-secondary'
            }
        },
        callback: function (result: any) {
            props.onValueChange(result);
        }
    });
};



export { ConfirmWithDataModal };
