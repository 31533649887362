
import './Style.css';
import ErrorIcon from '../../assets/images/ic_error.svg';
declare var bootbox: any;
 
const ErrorModal = (props: any) => {
    const dialog =bootbox.dialog({
        message: `<img class="rounded mx-auto d-block" src=`+ErrorIcon+` alt="Erroe"/>
                  <p class="text-center mt-3">`+props.message+`</p> `,
        closeButton: false
    });
    setTimeout(function(){
        dialog.modal('hide');
    }, 3000);
};
export {ErrorModal};