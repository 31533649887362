const OrderDays=[
    {key:'Sunday',value:"Sunday"},
    {key:'Monday',value:"Monday"},
    {key:'Tuesday',value:"Tuesday"},
    {key:'Wednesday',value:"Wednesday"},
    {key:'Thursday',value:"Thursday"},
    {key:'Friday',value:"Friday"},
    {key:'Saturday',value:"Saturday"}
];

const DeliveryDays=[
    {key:'Sunday',value:"Sun"},
    {key:'Monday',value:"Mon"},
    {key:'Tuesday',value:"Tue"},
    {key:'Wednesday',value:"Wed"},
    {key:'Thursday',value:"Thu"},
    {key:'Friday',value:"Fri"},
    {key:'Saturday',value:"Sat"}
];

const weekDays=["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const symbolsArr=["e", "E", "+", "-", "."];

export {OrderDays,DeliveryDays,weekDays,symbolsArr};