import { HolidayScheduleListPage } from "./HolidayScheduleListPage";
import './HolidaySchedulePage.css';
import { CloneSchedulePage } from "./clone-revert/CloneSchedulePage";
// import { RevertSchedulePage } from "./clone-revert/RevertSchedulePage";
// import { StaticOptionsPage } from "../../components";

const HolidayMaintenancePage = () => {

    return (
        <div>
            <div className="intra-cont">
                <div className="headding-cont">
                    <h2>Schedule Status</h2>
                    <p className="hsuptext mb-0">Publish/Activate a Holiday schedule</p>
                </div>
                {/* <StaticOptionsPage/> */}
            </div>
            <div className="round-cont mb-5">
                <HolidayScheduleListPage />
            </div>
            <div className="row">
                {/* <RevertSchedulePage /> */}
                <CloneSchedulePage />
            </div>
        </div>

    )
}
export { HolidayMaintenancePage };