import RaleyLogo from '../assets/images/Raleyslogo.svg';
import ProfileLogo from '../assets/images/profile.png';
import NotificationLogo from '../assets/images/notifications_bell.svg';
import SettingsLogo from '../assets/images/ic_topnav_setting.svg';
import SearchLogo from '../assets/images/Search_icon.svg';
import { useOktaAuth } from '@okta/okta-react';
import { getRoleAccess, getUserRole, history } from '../hoc';
import ProfileSm from '../assets/images/ic_profile.svg';
import LogoutSm from '../assets/images/ic_logout_sm.svg';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import './TopbarLayout.css';

const TopbarLayout = () => {
    const { authState } = useOktaAuth();
    const { userInfo } = useSelector((state: any) => state.user);
    const pathname = window.location.pathname.split("/");
    const [activeMenu, setActiveMenu] = useState("");
    useEffect(() => {
        setActiveMenu(pathname[pathname.length - 1]);
    }, [pathname]);

    const logOut = async (e: any) => {
        history.push('logout');
    }
    const navigate = (path: string) => {
        history.push(path);
    }

    return (
        <div>
            {authState && authState.isAuthenticated &&
                <nav className="top-nav d-flex">
                    <div className="branding"><img src={RaleyLogo} alt="Raley's" id="raleyLogo" onClick={() => navigate('/dashboard')} /></div>
                    <div className="header-cont">
                        {getRoleAccess('search') && <div className="search-cont">
                            <button onClick={() => navigate('/search')}>
                                <img src={SearchLogo} className={activeMenu === 'search' ? 'search-active' : ""} width="40" height="40" id="searchLogo" alt="searchLogo" />
                                <span>Search Order Schedules</span>
                            </button>
                        </div>}
                        <div className="profile-cont">
                            <div className="dropdown ">
                                <button className="d-block link-dark text-decoration-none dropdown-toggle profile-btn" id="dropdownUser1"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    {userInfo && <div className="d-inline-block profile-name">
                                        <label className="name" id="name">{userInfo.name}</label>
                                        <label className="pro" id="role">{getUserRole() + " User"}</label>
                                    </div>}
                                    <img src={ProfileLogo} alt="mdo" id="profileLogo" className="rounded-circle" />
                                </button>
                                <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                                    <li><button className="dropdown-item" id="profile"><img src={ProfileSm} width="35px" height="25px" id="profileSm" alt="profileSm" />Profile</button></li>
                                    <li><button className="dropdown-item" id="logout" onClick={logOut}><img src={LogoutSm} id="logoutSm" width="35px" height="25px" alt="logoutSm" />Logout</button></li>
                                </ul>
                            </div>

                            { false && <div className="noti-cont">
                                <img src={NotificationLogo} id="notificationLogo" alt="Notification" />
                                <div className="counter rounded-circle">9+</div>

                            </div>}
                            {getRoleAccess('maintenance') && <div className="logout-cont">
                                <img src={SettingsLogo} alt="setting" id="settingLogo" onClick={() => navigate('/maintenance')} />
                            </div>}
                        </div>
                    </div>
                </nav>}

        </div>

    )
}

export { TopbarLayout };