import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "../../components";
import { MatrixHeading, MtxSubHeading } from "../../constants";
import { generateKey } from "../../hoc";
import { searchMatrixActions } from '../../actions/search-matrix.actions';
import { createDynamicRow, filterMatrixList } from "./MatrixUtil";

const ScheduleMatrixPage = (props: any) => {
    const storeId = props.storeId?.storeId;
    const district = props.district?.district;

    const { matrixResult } = useSelector((state: any) => state.searchMatrix);
    const [deptList, setDeptList] = useState<Array<any>>([])
    const [state, setState] = useState({ dept: [{ majorDepartment: "All Departments" }] });
    const [filterResult, setFilterResult] = useState<Array<any>>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (storeId) {
            dispatch(searchMatrixActions.getMatrixResult(storeId, district));
        }
    }, [dispatch, storeId, district]);

    useEffect(() => {
        const depts = matrixResult.map(function (el: any) { return { majorDepartment: el.department }; });
        // depts.unshift({ majorDepartment: "Select All" });
        setDeptList(depts);
        setFilterResult(matrixResult);
    }, [matrixResult]);

    const handleChange = (e: any, key: string) => {
        setState(state => ({ ...state, [key]: e }));
        setFilterResult(filterMatrixList(matrixResult, e));
    }

    return (
        <div>
            <div className="title-sec">
                <p className="text-title-filter m-0">Date: <span className="date-heading">{moment().format('LL')}</span></p>
                <div className="title-right margin-btm" id="dept-dropdown">
                    <span className="text-title-filter">Department Filter : </span>
                    <MultiSelect key={generateKey()} options={deptList} label="majorDepartment" id="majorDepartment"  selectAllLabel="All Departments" value={state.dept} onChange={(options: any) => handleChange(options, 'dept')} />
                </div>
                <p className="text-title-filter m-0 schedule-title" id="schedule-title">Master Schedule Matrix</p>
            </div>
            <div className="container-fluid pb-3" id="matrixTable">
                <div className="print-table" id="matrixDiv">
                    <table className="table table-bordered" id='matrix-tbl'>
                        <thead>
                            <tr className="orderday" key={generateKey()}>
                                {MatrixHeading.map((head, index) => {
                                    return (<th scope="col" key={generateKey()} colSpan={2}>{head}</th>)
                                })}
                            </tr>
                            <tr className="order-head" key={generateKey()}>
                                <td key={generateKey()}>Department / Vendor</td>
                                <td key={generateKey()}>Transporter</td>
                                {MtxSubHeading.map((elm: any) => {
                                    return (<td key={generateKey()}>{elm}</td>)
                                })}
                            </tr>
                            
                        </thead>
                        {filterResult.length === 0 && <tbody key={generateKey()}><tr key={generateKey()} className="deparment-row"><td colSpan={16} key={generateKey()}><h6 className="text-center m-3">No Data Available</h6></td></tr></tbody>}

                        {filterResult.map((item: any) => {
                            return (
                                <tbody key={generateKey()}>
                                    <tr key={generateKey()} className="deparment-row">
                                        <td colSpan={16} key={generateKey()}>{item.department}</td>
                                    </tr>
                                    {createDynamicRow(item)}
                                </tbody>
                            )
                        })}
                    </table>
                </div>
            </div>
        </div>
    )
}

export { ScheduleMatrixPage };


