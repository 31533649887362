
import { SidebarLayout, TopbarLayout } from './layout';
// @ts-ignore
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { getRoleAccess, history } from './hoc';
import { CaoDashboardPage } from './containers/cao-dashboard/CaoDashboardPage';
import LoginPage from './LoginPage';
import { OktaConfig } from './okta.config';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import './App.css';
import { useState } from 'react';
import { ScheduleHomePage } from './containers/schedule/ScheduleHomePage';
import { HolidayMaintenancePage } from './containers/maintenance/HolidayMaintenancePage';
import { ScheduleSearchPage } from './containers/schedule-search/ScheduleSearchPage';
import { UtilityHomePage } from './containers/utility/UtilityHomePage';
import { LogoutPage } from './containers/logout/LogoutPage';
import { useIdleTimer } from 'react-idle-timer'
import { MatrixHomePage } from './containers/matrix/MatrixHomePage';

const oktaAuth = new OktaAuth(OktaConfig.oidc);
const timer = 1.8e+6;
function App() {
  const handleOnIdle = (event: any) => {
    if (getRemainingTime() === 0) {
      window.onbeforeunload = () => undefined;
    }
  }
  const { getRemainingTime } = useIdleTimer({
    timeout: timer,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true
    }
  });

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const [location, setLocation] = useState(history.location.pathname);
  history.listen((location, action) => {
    setLocation(location.pathname);
  });


  return (
    <Router history={history}>
      <div id="loader-cnt">
        <div id="loader-div"></div>
      </div>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <div className="wrapper">
          <Switch>
            <SecureRoute path="/dashboard" component={CaoDashboardPage} />
          </Switch>
          {location !== '/dashboard' && <TopbarLayout />}
          { location !== '/dashboard' && <div className="d-flex flex-cont">
            {location !== '/dashboard' && <SidebarLayout />}
            <section className="main-cont">
              <Switch>
                {getRoleAccess('matrix') && <SecureRoute path="/maintenance" component={HolidayMaintenancePage} />}
                {getRoleAccess('schedule') && <SecureRoute path="/schedule" component={ScheduleHomePage} />}
                {getRoleAccess('search') && <SecureRoute path="/search" component={ScheduleSearchPage} />}
                {getRoleAccess('matrix') && <SecureRoute path="/matrix" component={MatrixHomePage} />}
                {getRoleAccess('utility') && <SecureRoute path="/utility" component={UtilityHomePage} />}
                <Route path="/login/callback" component={LoginCallback} />
                <Route path="/logout" component={LogoutPage} />
                <Route path="/" component={LoginPage} />
                {/* {getRoleAccess('matrix') && <Route path="/cao/maintenance" component={HolidayMaintenancePage} />}
                {getRoleAccess('schedule') && <Route path="/cao/schedule" component={ScheduleHomePage} />}
                {getRoleAccess('search') && <Route path="/cao/search" component={ScheduleSearchPage} />}
                {getRoleAccess('matrix') && <Route path="/cao/matrix" component={MatrixHomePage} />*/}
                <Redirect from="*" to="/" />
              </Switch>
            </section>
          </div> }
        </div>
      </Security>
    </Router>
  );
}

export default App;


