import { DeliveryDays, warningConstants } from "../../../constants";
const sorter: any = {
    "Sun": 0,
    "Mon": 1,
    "Tue": 2,
    "Wed": 3,
    "Thu": 4,
    "Fri": 5,
    "Sat": 6,
}
const ScheduleWarnings = (state: any) => {
    let warnings: any = {};
    if (state["orderDay"] && state['deliveryDay']) {
        let data = DeliveryDays.find((elm: any) => elm.key === state["orderDay"]);
        if (data && data['value'] === state['deliveryDay']) {
            warnings["deliveryDay"] = warningConstants.DELIVERYDAYSAME;
        }
        let orderDay = state["orderDay"].substring(0, 3);
        let value = (sorter[orderDay] - sorter[state["deliveryDay"]]);

        if (value !== 6 && (sorter[orderDay] > sorter[state["deliveryDay"]] || value > 1 || value < -1)) {
            warnings["deliveryDay"] = warningConstants.DONOTCONSECUTIVE;
        }
    }
    if (sorter[state["onShelfDay"]] === sorter[state["deliveryDay"]]) {
        warnings["onShelfDay"] = warningConstants.ONSHELFDAYSAME;
    }
    let value = (sorter[state["deliveryDay"]] - sorter[state["onShelfDay"]]);

    if (value !== 6 && (sorter[state["deliveryDay"]] > sorter[state["onShelfDay"]] || value > 1 || value < -1)) {
        warnings["onShelfDay"] = warningConstants.ONSHELFNOTCONSECUTIVE;
    }



    return { warnings };
}

const warningState = {
    deliveryDay: '',
    onShelfDay: ''
}
export { ScheduleWarnings, warningState };