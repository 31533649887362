import axios from 'axios';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaConfig } from '../okta.config';
let loadingCount:number=0;
// note: any headers added here need to be whitelisted in the Atom's Shared Web Server CORS config
let authHeader = {
    //'Authorization': "",
    'X-WSS-AccessToken': "",
    'Content-Type': "application/json",
    //'userName': "",
    //'email': "",
    //'userId': "",
    //'Access-Control-Allow-Origin':"",
}

// Create instance
let fetch = axios.create({
    //baseURL: 'https://api.raleys-dev.com/cao-odtschedule/',
    //baseURL: 'http://localhost:8081/',
    baseURL: 'https://trc-test.boomi.cloud:5005/ws/rest/logistics/odt/v1/',
    timeout: 3000 * 16,
    headers: authHeader,
    auth: {
        username: 'odt@raleyssupermarket-CTN8MQ.ZZ9ERE',
        password: 'defbd88a-bbe1-47c9-b47c-05b578e447c9'
    }
});

fetch.interceptors.request.use(async (config) => {
    addLoader();
    const oktaAuth = new OktaAuth(OktaConfig.oidc);
    const token = await oktaAuth.tokenManager.getTokens();
    config.headers["X-WSS-AccessToken"] = token.accessToken?.accessToken;
    //config.headers.userName = token.idToken?.claims?.name;
    //config.headers.email = token.idToken?.claims?.email;
    //config.headers.userId = token.idToken?.claims?.sub;

    // this code is to inject the boomi_auth url postfix to the slug (before the queries, but after the full path) so that the CORS request can be authorized
    // the value of the boomi_auth postfix is the base64 encoded user:token string
    // read more at https://raleys.atlassian.net/wiki/spaces/IAD/pages/3379757077/Cross-Origin+Resource+Sharing+CORS+Resources#Boomi-Auth
    // if the boomi_auth postfix is missing the CORS pre-flight request will never validate and instead return a 403 Forbidden meaning all requests will fail
    
    // note! this doesn't account for the case when there is a trailing slash after a url path, for example "/stores/?foo=bar" will be split like ["/stores/", "foo=bar"]
    // meaning the boomi_auth will be appended post splash ie /stores/;boomi_auth?foo=bar (which might not work!)
    var splitURLByQueries = config.url.split('?');
    //config.url = splitURLByQueries[0] + ';boomi_auth=b2R0QHJhbGV5c3N1cGVybWFya2V0LUNUTjhNUS5aWjlFUkU6ZGVmYmQ4OGEtYmJlMS00N2M5LWI0N2MtMDViNTc4ZTQ0N2M5' + (splitURLByQueries[1] ? ("?" + splitURLByQueries[1]) : "");
    config.url = splitURLByQueries[0] + ';boomi_auth=' + Buffer.from(config.auth.username + ":" + config.auth.password).toString('base64') + (splitURLByQueries[1] ? ("?" + splitURLByQueries[1]) : "");

    return config;
}, async (error) => {
    removeLoader();
    return Promise.reject(error);
});

fetch.interceptors.response.use(async (response) => {
    removeLoader();
    return response;
}, async (error) => {
    removeLoader();
    return Promise.reject(error);
});

const removeLoader = () => {
    loadingCount= loadingCount-1;
    if(loadingCount === 0){
        let loderCnt: any = document.getElementById('loader-cnt');
        loderCnt.classList.remove("loader-cnt");
        let loader: any = document.getElementById('loader-div');
        loader.classList.remove("loader");
    }
}
const addLoader = () => {
    loadingCount= loadingCount+1;
    let loderCnt: any = document.getElementById('loader-cnt');
    loderCnt.classList.add("loader-cnt");
    let loader: any = document.getElementById('loader-div');
    loader.classList.add("loader");
}
export { fetch };