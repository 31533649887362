import moment from "moment";

const getSearchMapping=(currentState:any,effectiveStart:any,effectiveEnd:any)=>{
    let dDay=currentState.deliveryDay?.value;
    let data:any={
        holidayId:currentState.namedSchedule?.holidayId,
        store:currentState.store?.storeId,
        majorDepartment:currentState.majorDepartment?.majorDepartment,
        district:currentState.district?.district,
        source:currentState.source?.source,
        minorDepartment:currentState.minorDepartment?.minorDepartment,
        vendorId:currentState.vendorId?.vendorId,
        orderDay:currentState.orderDay?.value,
        deliveryDay:dDay ? dDay.substring(0,3):"",
        orderCutoffTime:currentState.orderCutoffTime,
        windowBegin:currentState.deliveryWindowBegin,
        windowEnd:currentState.deliveryWindowEnd,
    }
    if(effectiveStart && effectiveEnd){
        data['effectiveDateFrom']=moment(new Date(effectiveStart)).format('MM-DD-YYYY').replaceAll("-", "/");
        data['effectiveDateTo']=moment(new Date(effectiveEnd)).format('MM-DD-YYYY').replaceAll("-", "/");
    }
    return data;
}

const validate=(currentState:any,effectiveStart:any,effectiveEnd:any)=>{
    let data:any = getSearchMapping(currentState,effectiveStart,effectiveEnd);
    let valid=true;
    if(data.holidayId < 0 || !data.majorDepartment || (!data.store && !data.district) ){
        valid=false;
    }
    return valid;      
}

const between = (arr:Array<any>, from: number, to: number, inclusive = true) => {
    let result=[];
    if (inclusive) {
        result= arr.filter((x, i) => {
            if (i >= (from - 1) && i <= (to - 1)) { return true }
            else{return false}
        })
    }
    if (!inclusive) {
        result= arr.filter((x, i) => {
            if (i > (from - 1) && i < (to - 1)) { return true }
            else{return false}
        })
    }
    return result;
}

const validateDateRange = (startDate:any,endDate:any) => {
    let valid = true;
    if (startDate && endDate) {
        let start = new Date(startDate);
        let end = new Date(endDate);
        let time_diff = start.getTime() - end.getTime();
        let diff_days = time_diff / (1000 * 3600 * 24);
        if(diff_days > 6){
            valid=false;
        }
    }
    return valid;
}
export {getSearchMapping,validate,between,validateDateRange};