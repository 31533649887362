import { combineReducers } from 'redux';
import { schedule } from './schedule.reducer';
import { store } from './store.reducer';
import {vendor} from './vendor.reducer';
import { fschedule } from './future-schedule.reducer';
import { holiday } from './holiday.reducer';
import { user } from './user.reducer';
import { searchMatrix } from './search-matrix.reducer';


const rootReducer = combineReducers({
    schedule,
    vendor,
    fschedule,
    holiday,
    store,
    user,
    searchMatrix
});

export default rootReducer;