import { generateKey } from "../../hoc";
import Select from "react-select";

const DropDown = (props: any) => {

    return (
        <div className="mb-3">
            <Select
                value={props.value}
                isClearable={false}
                key={generateKey()}
                placeholder={<div>Search</div>}
                name={props.id}
                onChange={props.onChange}
                getOptionValue={(option: any) => option[props.label]}
                getOptionLabel={(option: any) => option[props.label]}
                options={props.options}
            />
        </div>
    )
}
export { DropDown };
