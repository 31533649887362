export const vendorStoreConstants = {  
    GET_STORE_REQUEST: 'GET_STORE_REQUEST',
    GET_STORE_SUCCESS: 'GET_STORE_SUCCESS',
    GET_STORE_FAILURE: 'GET_STORE_FAILURE',

    GET_VENDOR_REQUEST: 'GET_VENDOR_REQUEST',
    GET_VENDOR_SUCCESS: 'GET_VENDOR_SUCCESS',
    GET_VENDOR_FAILURE: 'GET_VENDOR_FAILURE',

    GET_VDEPT_REQUEST: 'GET_VDEPT_REQUEST',
    GET_VDEPT_SUCCESS: 'GET_VDEPT_SUCCESS',
    GET_VDEPT_FAILURE: 'GET_VDEPT_FAILURE',

    GET_SDIST_REQUEST: 'GET_SDIST_REQUEST',
    GET_SDIST_SUCCESS: 'GET_SDIST_SUCCESS',
    GET_SDIST_FAILURE: 'GET_SDIST_FAILURE',

    GET_SBYDIST_REQUEST: 'GET_SBYDIST_REQUEST',
    GET_SBYDIST_SUCCESS: 'GET_SBYDIST_SUCCESS',
    GET_SBYDIST_FAILURE: 'GET_SBYDIST_FAILURE',

    GET_MIDEPT_REQUEST: 'GET_MIDEPT_REQUEST',
    GET_MIDEPT_SUCCESS: 'GET_MIDEPT_SUCCESS',
    GET_MIDEPT_FAILURE: 'GET_MIDEPT_FAILURE',

    GET_SOURCE_REQUEST: 'GET_SOURCE_REQUEST',
    GET_SOURCE_SUCCESS: 'GET_SOURCE_SUCCESS',
    GET_SOURCE_FAILURE: 'GET_SOURCE_FAILURE',
};