
import { AxiosResponse } from 'axios';
import { ErrorModal } from '../components';
import { searchMatrixConstants } from '../constants';
import {fetch} from '../hoc';


export const searchMatrixActions = {
    getSearchResult,
    getMatrixResult,
    resetSearchResult,
    resetMatrixResult
};

function getSearchResult(data:any) {
    return (dispatch:Function) => {
        dispatch(requestSearch());
        fetch({
            method: 'post',
            url:getDynamicUrlStr(data),
            data:data   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function success(response: AxiosResponse<any>) { return { type: searchMatrixConstants.GET_SEARCH_SUCCESS, response } }
    function failure(error: any) { return { type: searchMatrixConstants.GET_SEARCH_FAILURE, error } }
}

function requestSearch() { return { type: searchMatrixConstants.GET_SEARCH_REQUEST } };

function getDynamicUrlStr(data:any){
    let urlStr="searchSchedule/list/"+data.majorDepartment+"/"+data.holidayId;
    if(data.store && !data.district){
      urlStr= urlStr+"?store="+data.store;
    }
    if(data.district && !data.store){
        urlStr= urlStr+"?district="+data.district;
    }
    if(data.store && data.district){
        urlStr= urlStr+"?store="+data.store+"&district="+data.district;
    }
    return urlStr;
}


function getMatrixResult(storeId:number,district?:string) {
    return (dispatch:Function) => {
        let url = 'matrix/master/'+storeId;
        if(district){url+="?district="+district;}
        dispatch(request());
        fetch({
            method: 'get',
            url:url,
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: searchMatrixConstants.GET_MATRIX_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: searchMatrixConstants.GET_MATRIX_SUCCESS, response } }
    function failure(error: any) { return { type: searchMatrixConstants.GET_MATRIX_FAILURE, error } }
}

function resetSearchResult(){
    return (dispatch:Function) => {
        dispatch(requestSearch());
    };
}
function resetMatrixResult(){
    return (dispatch:Function) => {
        dispatch({ type: searchMatrixConstants.GET_MATRIX_REQUEST });
    };
}