import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { history } from './hoc';
import RaleyLogo from './assets/images/raleyslogo_red.svg';

const LoginPage = () => {

    const { authState, oktaAuth } = useOktaAuth();
    useEffect(() => {
        if (authState && !authState.isAuthenticated) {
            oktaAuth.signInWithRedirect({ originalUri: '/' });
        } else if (authState && authState.isAuthenticated) {
            history.push('/dashboard');
        }
    }, [authState, oktaAuth]);
    return (
        <div>
            {(authState && !authState.isAuthenticated) &&
                <div className="container">
                    <div className="logout-cont my-5">
                        <div className="text-center" > <img className="mx-auto" src={RaleyLogo} alt="" width="100" /></div>
                        <h5 className="text-secondary text-center pt-3">Loading....</h5>
                    </div>
                </div>
            }
        </div>
    )
}
export default LoginPage;