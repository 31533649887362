import { ConfirmModal, ConfirmWithDataModal, DateTimePicker } from "../../components";
import { useState, useEffect } from 'react';
import './HolidaySchedulePage.css';
import { useSelector, useDispatch } from 'react-redux';
import { holidayActions } from "../../actions";
import { messageConstants,MaintenanceTableHeading } from "../../constants";
import moment from "moment";
import TrashImg from '../../assets/images/ic_trash.svg';
import { Prompt } from "react-router";
import { checkUnsavedChanges } from "./HolidayUtils";

const HolidayScheduleListPage = () => {
    const headers = MaintenanceTableHeading;
    const {result}= useSelector((state:any)=>state.holiday);
    const [holidayList, setHolidayList] = useState<Array<any>[]>([]);
    const [errors,setErrors] = useState({endDate:""});
    const dispatch= useDispatch();
    const todayDate= new Date();
    const tomorrowDate = new Date();
    tomorrowDate.setDate(todayDate.getDate()+2);

    useEffect(()=>{
        dispatch(holidayActions.getHolidayList());
    },[dispatch]);

    useEffect(() => {
        setHolidayList(result);
    }, [result]);

    const onDateChange = (e: any, name: string, index: number) => {
        let list: Array<any> = [...holidayList];
        let date= moment(new Date(e)).format('MM-DD-YYYY').replaceAll("-", "/");
        if(date !== list[index][name]){
            list[index]['isSaved']= false;
        }
        list[index][name] = date;
        setHolidayList(list);
    }

    const updateHoliday=(row:any)=>{
        if(validate(row)){  
            ConfirmWithDataModal({data:row,message:messageConstants.UPDATE_HOLIDAY_CONFIRM,confirm:"Yes",cancel:"No",onValueChange:function onValueChange(confirm:any){
              if(confirm){
                dispatch(holidayActions.updateHoliday(row));
              }    
            }});
        }
    }

    const publishHoliday=(row:any)=>{
      if(validate(row)){
        ConfirmWithDataModal({data:row,message:messageConstants.PUBLISH_HOLIDAY_CONFIRM,confirm:"Yes",cancel:"No",onValueChange:function onValueChange(confirm:any){
            if(confirm){
                dispatch(holidayActions.publishHoliday(row));
            }    
          }});
      }  
    }
    const activateHoliday=(row:any)=>{
        if(validate(row)){
            ConfirmWithDataModal({data:row,message:messageConstants.ACTIVATE_HOLIDAY_CONFIRM,confirm:"Yes",cancel:"No",onValueChange:function onValueChange(confirm:any){
                if(confirm){
                    dispatch(holidayActions.activateHoliday(row));
                }    
              }});
        }
    }
    const deleteHoliday=(row:any)=>{
        ConfirmModal({message:messageConstants.DELETE_HOLIDAY_CONFIRM,confirm:"Yes",cancel:"No",onValueChange:function onValueChange(confirm:any){
            if(confirm){
                dispatch(holidayActions.deleteHoliday(row.holidayId));
            }
        }});
    }
    
    const validate=(data:any)=>{
          const {effectiveDate,endDate} = data;
          if(new Date(endDate).getTime() <= new Date(effectiveDate).getTime()){
              setErrors({endDate:data.namedSchedule+": End date must be after the effective date."});
              window.scrollTo(0, 0);
              return false;
          }else if(new Date(effectiveDate).getTime() <= tomorrowDate.getTime()){
            setErrors({endDate:data.namedSchedule+": Effective date must be a future date and not today or tomorrow’s date"});
            window.scrollTo(0, 0);
            return false;
          }else{
              setErrors({endDate:""});
              return true;
          }
    }
  
    return (
        <div className="maintenance-cont p-3">
            {errors.endDate  && <span className="error-toast mt-2">{errors.endDate}</span>}
            <div className="scroll-cont">
            <table className="table maint-table" id='maintenance'>
                <thead className="table-dark static-thead">
                    <tr>
                        {headers.map((head, index) => {
                            return (<th scope="col" key={index}>{head}</th>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    {holidayList.length === 0 && <tr><td colSpan={5}><h6 className="text-center m-3">No Data Available</h6></td></tr>}
                    {
                        holidayList.map((row: any, index: number) => {
                            return (
                                <tr key={index}>
                                    <td> {row.namedSchedule}</td>
                                    <td>
                                        {(row.status !== 'Publish' && row.status !== 'Create') && row.effectiveDate}
                                        {(row.status === 'Publish' || row.status === 'Create') &&
                                            <DateTimePicker
                                                dateFormat="MM/dd/yyyy"
                                                className="form-control mb-0 date-control"
                                                name="effectiveDate"
                                                autoComplete="off"
                                                minDate={tomorrowDate}
                                                onChange={(e: any) => onDateChange(e, 'effectiveDate', index)}
                                                onKeyDown={(e: any) => e.preventDefault()}
                                                selected={row.effectiveDate ? new Date(row.effectiveDate) : row.effectiveDate} />
                                        }
                                    </td>
                                    <td>
                                        {(row.status !== 'Publish' && row.status !== 'Create') && row.endDate}
                                        {(row.status === 'Publish' || row.status === 'Create') &&
                                            <DateTimePicker
                                                dateFormat="MM/dd/yyyy"
                                                className="form-control mb-0 date-control"
                                                name="endDate"
                                                autoComplete="off"
                                                minDate={tomorrowDate}
                                                onChange={(e: any) => onDateChange(e, 'endDate', index)}
                                                onKeyDown={(e: any) => e.preventDefault()}
                                                selected={row.endDate ? new Date(row.endDate) : row.endDate} />
                                        }
                                      { (!row.endDate && row.namedSchedule === 'Master') && <span>-</span>}
                                    </td>
                                    <td>
                                        {(row.status === 'Active' || row.status === 'Create' || row.status === 'Publish') && row.namedSchedule !== 'Master' && <span className="btn pillbtn pill-pending">{row.status}</span>}
                                        {row.status === 'Inactive' && <span className="btn pillbtn pill-inactive">Inactive</span>}
                                        {row.status === 'Deleted' && <span className="btn pillbtn pill-delete">Deleted</span>}
                                        {row.namedSchedule === 'Master' && <span className="btn pillbtn pill-active">Active</span>}

                                    </td>
                                    <td>
                                        {row.status === 'Create' &&<button type="button" className="btn btn-primary btn-sm me-2" disabled={!row.endDate || !row.effectiveDate} id="publish-btn" onClick={()=>publishHoliday(row)}>PUBLISH</button>}
                                        {row.status === 'Publish' && <button type="button" className="btn btn-primary btn-sm me-2" disabled={!row.endDate || !row.effectiveDate} id="active-btn" onClick={()=>activateHoliday(row)}>ACTIVATE</button>}
                                        {(row.status === 'Publish' || row.status === 'Create') && <button type="button" className="btn btn-primary btn-sm me-2" disabled={!row.endDate || !row.effectiveDate} id="update-btn" onClick={()=>updateHoliday(row)}>UPDATE</button>}
                                        {row.status === 'Create' && <button className="btn btn-outline-secondary"><img alt="deleteBtn" src={TrashImg} id="delete-btn" onClick={()=>deleteHoliday(row)}/> </button>}

                                    </td>
                                </tr>
                            )
                        })
                    }


                </tbody>
            </table>
            </div>
            <Prompt
                when={checkUnsavedChanges(holidayList)}
                message={messageConstants.PAGE_LEAVE_WARN}
            />
        </div>
    )
}

export { HolidayScheduleListPage };