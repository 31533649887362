
import { AxiosResponse } from 'axios';
import { ErrorModal, SuccessModal } from '../components';
import { scheduleConstants } from '../constants';
import {fetch, SortWeekDays} from '../hoc';

export const scheduleActions = {
    getCurrentSchedule,
    saveNewSchedule,
    getScheduleHistory,
    getWeeklyScheduleList,
    resetSchduleResult
};

function getCurrentSchedule(storeId:number,vendorId:number) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url: 'currentSchedule/list/'+storeId+"/"+vendorId,   
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: scheduleConstants.GET_WSCHEDULE_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: scheduleConstants.GET_WSCHEDULE_SUCCESS, response } }
    function failure(error: any) { return { type: scheduleConstants.GET_WSCHEDULE_FAILURE, error } }
}

function saveNewSchedule(schedules:Array<any>,storeId:number,vendorId:number) {
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url: 'newSchedule/save/'+storeId+"/"+vendorId, 
            data:{list:schedules}  
        }).then(function (response) {
            if(response.data.status === 200){
                dispatch(success(response));
                dispatch(updateCurrentScheduleState(schedules,response.data.result));
                SuccessModal(response.data.message);
            }else{
                ErrorModal(response.data);  
            }   
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: scheduleConstants.SAVE_WSCHEDULE_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: scheduleConstants.SAVE_WSCHEDULE_SUCCESS, response } }
    function failure(error: any) { return { type: scheduleConstants.SAVE_WSCHEDULE_FAILURE, error } }
}

function updateCurrentScheduleState(reqArr:Array<any>,resArr:Array<any>) {
     let arr= reqArr.filter((row:any) => row.deleteFlag !== 1);
     if(arr.length){
        resArr.forEach((row:any)=>{
           let schedule = reqArr.find(elem=> elem.orderDay === row.orderDay);
           if(schedule){
             schedule.dsId= row.dsId; 
           }
        });
        arr=SortWeekDays(arr, 'orderDay');
     }
     return { type: scheduleConstants.GET_WSCHEDULE_SUCCESS, response:{data:{result:arr}} };
}

function getScheduleHistory(reqObj:any,storeId:number,vendorId:number){
    const {year,pageNo,limit}=reqObj;
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'get',
            url:'history/list/'+storeId+"/"+vendorId+"/"+year+"?pageNo="+pageNo+"&pageSize="+limit,
            data:reqObj  
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: scheduleConstants.GET_SHISTORY_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: scheduleConstants.GET_SHISTORY_SUCCESS, response } }
    function failure(error: any) { return { type: scheduleConstants.GET_SHISTORY_FAILURE, error } }  
}

function getWeeklyScheduleList(){
    return (dispatch:Function) => {
        dispatch(request());
        fetch({
            method: 'post',
            url:'schedule/list',
            data:[]  
        }).then(function (response) {
            dispatch(success(response));
        }).catch(error=>{
            dispatch(failure(error.message));
            ErrorModal(error);
        });
      
    };
    function request() { return { type: scheduleConstants.GET_WSCHEDULELIST_REQUEST } }
    function success(response: AxiosResponse<any>) { return { type: scheduleConstants.GET_WSCHEDULELIST_SUCCESS, response } }
    function failure(error: any) { return { type: scheduleConstants.GET_WSCHEDULELIST_FAILURE, error } }  
}

function resetSchduleResult(){
    return (dispatch:Function) => {
        dispatch({ type: scheduleConstants.GET_WSCHEDULE_REQUEST });
    };
}