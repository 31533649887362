import { generateKey } from "../../hoc";

const Select = (props: any) => {
    return (
        <select
            className="form-select"
            name={props.name}
            value={props.value}
            onChange={props.onChange}>
            {props.defaultLabel && <option className="d-none" value="">{props.defaultLabel}</option>}
            {props.items.map((row: string) => {
                return <option key={generateKey()} value={row[props.labelValue]}>{row[props.label]}</option>;
            })}
        </select>
    )   
}

export { Select };