import { scheduleConstants } from "../constants";
const initialState = {
    result: [],
    newSchedule: [],
    history: [],
    weeklyScheduleList:[{ storeId: "102", vendor: "Test-102", result: [] },
    { storeId: "103", vendor: "Test-102", result: [{"dsId":0,"orderDay":"Sunday","orderCutoffTime":"00:00","deliveryDay":"Sun","deliveryWindowBegin":"00:00","deliveryWindowEnd":"00:00","effectiveDate":"10/02/2021","onShelfDay":"Sun","onShelfHours":8,"createLeadTime":18,"deliveryHour":24}] }] 
};

export function schedule(state = initialState, action: any) {
    switch (action.type) {
        case scheduleConstants.GET_WSCHEDULE_REQUEST:
            return { ...state,result:[] };
        case scheduleConstants.GET_WSCHEDULE_SUCCESS:
            return {
                ...state,
                result: action.response.data.result
            };
        case scheduleConstants.GET_WSCHEDULE_FAILURE:
            return { ...state };
        case scheduleConstants.SAVE_WSCHEDULE_REQUEST:
            return { ...state };
        case scheduleConstants.SAVE_WSCHEDULE_SUCCESS:
            return { ...state, newSchedule: action.response.data.result };
        case scheduleConstants.SAVE_WSCHEDULE_FAILURE:
            return { ...state };
        case scheduleConstants.GET_SHISTORY_REQUEST:
            return { ...state };
        case scheduleConstants.GET_SHISTORY_SUCCESS:
            return { ...state, history: action.response.data.result };
        case scheduleConstants.GET_SHISTORY_FAILURE:
            return { ...state };
        case scheduleConstants.GET_WSCHEDULELIST_REQUEST:
            return { ...state };
        case scheduleConstants.GET_WSCHEDULELIST_SUCCESS:
            return { ...state, weeklyScheduleList: action.response.data.result };
        case scheduleConstants.GET_WSCHEDULELIST_FAILURE:
            return { ...state };
        default:
            return state
    }
}